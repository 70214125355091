import React from 'react'
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonCol,
    IonContent,
    IonGrid,
    IonRow,
    IonText,
    IonLabel,
    IonCard,
} from '@ionic/react'
import Header from './admin-header'
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    BarChart,
    Bar,
    Legend,
    RadarChart,
    Radar,
    PolarGrid,
    PolarAngleAxis,
    PolarRadiusAxis,
    PieChart,
    Pie,
    Cell,
} from 'recharts'
import { Helmet } from 'react-helmet'
const data = [
    {
        name: 'Page A',
        uv: 4000,
        pv: 2400,
        amt: 2400,
    },
    {
        name: 'Page B',
        uv: 3000,
        pv: 1398,
        amt: 2210,
    },
    {
        name: 'Page C',
        uv: 2000,
        pv: 9800,
        amt: 2290,
    },
    {
        name: 'Page D',
        uv: 2780,
        pv: 3908,
        amt: 2000,
    },
    {
        name: 'Page E',
        uv: 1890,
        pv: 4800,
        amt: 2181,
    },
    {
        name: 'Page F',
        uv: 2390,
        pv: 3800,
        amt: 2500,
    },
    {
        name: 'Page G',
        uv: 3490,
        pv: 4300,
        amt: 2100,
    },
]

const data3 = [
    {
        subject: 'Math',
        A: 120,
        B: 110,
        C: 90,
        fullMark: 150,
    },
    {
        subject: 'Chinese',
        A: 98,
        B: 130,
        C: 40,
        fullMark: 150,
    },
    {
        subject: 'English',
        A: 86,
        B: 130,
        C: 90,
        fullMark: 150,
    },
    {
        subject: 'Geography',
        A: 99,
        B: 100,
        C: 100,
        fullMark: 150,
    },
    {
        subject: 'Physics',
        A: 120,
        B: 90,
        C: 80,
        fullMark: 150,
    },
    {
        subject: 'History',
        A: 130,
        B: 85,
        C: 65,
        fullMark: 150,
    },
]

const data4 = [
    { name: 'Group A', value: 400 },
    { name: 'Group B', value: 300 },
    { name: 'Group C', value: 300 },
    { name: 'Group D', value: 200 },
]
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042']

const AdminDashboard = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>LifeDNA | ADMIN | Admin Dashboard</title>
            </Helmet>
            <IonPage>
                <Header />
                <IonContent>
                    <IonHeader>
                        <IonToolbar>
                            <IonTitle>Admin Analytics</IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <IonGrid>
                        <IonRow>
                            <IonCol
                                size-xs="12"
                                size-sm="12"
                                size-md="6"
                                size-xl="3"
                            >
                                <IonCard
                                    className="ion-padding"
                                    color="tertiary"
                                >
                                    <IonText color="light">
                                        <h1>
                                            <b>262</b>
                                        </h1>
                                    </IonText>
                                    <IonText color="light">
                                        <h4>
                                            <b>New Orders</b>
                                        </h4>
                                    </IonText>
                                </IonCard>
                            </IonCol>
                            <IonCol
                                size-xs="12"
                                size-sm="12"
                                size-md="6"
                                size-xl="3"
                            >
                                <IonCard
                                    className="ion-padding"
                                    color="success"
                                >
                                    <IonText color="light">
                                        <h1>
                                            <b>463</b>
                                        </h1>
                                    </IonText>
                                    <IonText color="light">
                                        <h4>
                                            <b>Users</b>
                                        </h4>
                                    </IonText>
                                </IonCard>
                            </IonCol>
                            <IonCol
                                size-xs="12"
                                size-sm="12"
                                size-md="6"
                                size-xl="3"
                            >
                                <IonCard
                                    className="ion-padding"
                                    color="warning"
                                >
                                    <IonText color="light">
                                        <h1>
                                            <b>1,950</b>
                                        </h1>
                                    </IonText>
                                    <IonText color="light">
                                        <h4>
                                            <b>Registrations</b>
                                        </h4>
                                    </IonText>
                                </IonCard>
                            </IonCol>
                            <IonCol
                                size-xs="12"
                                size-sm="12"
                                size-md="6"
                                size-xl="3"
                            >
                                <IonCard className="ion-padding" color="danger">
                                    <IonText color="light">
                                        <h1>
                                            <b>3,684</b>
                                        </h1>
                                    </IonText>
                                    <IonText color="light">
                                        <h4>
                                            <b>New Visitors</b>
                                        </h4>
                                    </IonText>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol
                                size-xs="12"
                                size-sm="12"
                                size-md="12"
                                size-xl="8"
                            >
                                <IonCard className="ion-padding">
                                    <IonText color="dark">
                                        <h4>
                                            <b>Sales</b>
                                        </h4>
                                    </IonText>
                                    <div style={{ width: '100%', height: 400 }}>
                                        <ResponsiveContainer
                                            width="100%"
                                            height="100%"
                                        >
                                            <AreaChart
                                                width={500}
                                                height={400}
                                                data={data}
                                                margin={{
                                                    top: 10,
                                                    right: 30,
                                                    left: 0,
                                                    bottom: 0,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="name" />
                                                <YAxis />
                                                <Tooltip />
                                                <Area
                                                    type="monotone"
                                                    dataKey="uv"
                                                    stackId="1"
                                                    stroke="#880808"
                                                    fill="#D22B2B"
                                                />
                                                <Area
                                                    type="monotone"
                                                    dataKey="pv"
                                                    stackId="1"
                                                    stroke="#ffc658"
                                                    fill="#ffc658"
                                                />
                                                <Area
                                                    type="monotone"
                                                    dataKey="amt"
                                                    stackId="1"
                                                    stroke="#82ca9d"
                                                    fill="#82ca9d"
                                                />
                                            </AreaChart>
                                        </ResponsiveContainer>
                                    </div>
                                </IonCard>
                            </IonCol>
                            <IonCol
                                size-xs="12"
                                size-sm="12"
                                size-md="12"
                                size-xl="4"
                            >
                                <IonCard className="ion-padding">
                                    <IonText color="dark">
                                        <h4>
                                            <b>Earns</b>
                                        </h4>
                                    </IonText>
                                    <div style={{ width: '100%', height: 400 }}>
                                        <ResponsiveContainer>
                                            <PieChart>
                                                <Pie
                                                    dataKey="value"
                                                    data={data4}
                                                    fill="#8884d8"
                                                    label
                                                >
                                                    {data.map(
                                                        (entry, index) => (
                                                            <Cell
                                                                key={`cell-${index}`}
                                                                fill={
                                                                    COLORS[
                                                                        index %
                                                                            COLORS.length
                                                                    ]
                                                                }
                                                            />
                                                        )
                                                    )}
                                                </Pie>
                                            </PieChart>
                                        </ResponsiveContainer>
                                    </div>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol
                                size-xs="12"
                                size-sm="12"
                                size-md="12"
                                size-xl="6"
                            >
                                <IonCard className="ion-padding">
                                    <IonText color="dark">
                                        <h4>
                                            <b>Products</b>
                                        </h4>
                                    </IonText>
                                    <div style={{ width: '100%', height: 400 }}>
                                        <ResponsiveContainer
                                            width="100%"
                                            height="100%"
                                        >
                                            <BarChart
                                                width={500}
                                                height={300}
                                                data={data}
                                                margin={{
                                                    top: 20,
                                                    right: 30,
                                                    left: 20,
                                                    bottom: 5,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="name" />
                                                <YAxis />
                                                <Tooltip />
                                                <Legend />
                                                <Bar
                                                    dataKey="pv"
                                                    stackId="a"
                                                    fill="#0088FE"
                                                />
                                                <Bar
                                                    dataKey="uv"
                                                    stackId="a"
                                                    fill="#FFBB28"
                                                />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </div>
                                </IonCard>
                            </IonCol>
                            <IonCol
                                size-xs="12"
                                size-sm="12"
                                size-md="12"
                                size-xl="6"
                            >
                                <IonCard className="ion-padding">
                                    <IonText color="dark">
                                        <h4>
                                            <b>Income</b>
                                        </h4>
                                    </IonText>
                                    <div style={{ width: '100%', height: 400 }}>
                                        <ResponsiveContainer
                                            width="100%"
                                            height="100%"
                                        >
                                            <AreaChart
                                                width={500}
                                                height={400}
                                                data={data}
                                                margin={{
                                                    top: 10,
                                                    right: 30,
                                                    left: 0,
                                                    bottom: 0,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="name" />
                                                <YAxis />
                                                <Tooltip />
                                                <Area
                                                    type="monotone"
                                                    dataKey="uv"
                                                    stroke="#8884d8"
                                                    fill="#8884d8"
                                                />
                                            </AreaChart>
                                        </ResponsiveContainer>
                                    </div>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonContent>
            </IonPage>
        </React.Fragment>
    )
}

export default AdminDashboard
