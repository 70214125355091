import React, { useState, useEffect } from 'react'

import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonCol,
    IonContent,
    IonGrid,
    IonRow,
    IonButton,
    IonIcon,
} from '@ionic/react'
import { trashOutline, addOutline, createOutline } from 'ionicons/icons'
import {
    MDBDataTableV5,
    MDBModal,
    MDBModalHeader,
    MDBModalBody,
    MDBModalFooter,
} from 'mdbreact'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbreact/dist/css/mdb.css'
import makeAnimated from 'react-select/animated'
import CreatableSelect from 'react-select/creatable'

import api from '../Api'
import Header from './admin-header'
import { Helmet } from 'react-helmet'

const animatedComponents = makeAnimated()

const AdminReport = () => {
    const [showModal, setShowModal] = useState(false)
    const [update, setUpdate] = useState(false)
    const [selectedTags, setSelectedTags] = useState([])
    const [reports, setReports] = useState([])
    const [tags, setTags] = useState([])
    const [state, setState] = useState({
        id: '',
        name: '',
        description: '',
        tag: '',
        tags: [],
    })

    const getAllReports = async () => {
        try {
            const response = await api.get('/reports')
            setReports({
                columns: [
                    {
                        label: '',
                        field: 'checkbox',
                        width: 30,
                        sort: 'disabled',
                    },
                    {
                        label: '#',
                        field: 'number',
                        width: 30,
                    },
                    {
                        label: 'Name',
                        field: 'name',
                        width: 200,
                        attributes: {
                            'aria-controls': 'DataTable',
                            'aria-label': 'Name',
                        },
                    },

                    {
                        label: 'Description',
                        field: 'description',
                        width: 200,
                    },
                    {
                        label: 'Short Description',
                        field: 'tag',
                        width: 100,
                    },
                    {
                        label: 'Tags',
                        field: 'tags',
                        width: 300,
                    },
                    {
                        label: '',
                        field: 'action',
                        width: 30,
                        sort: 'disabled',
                    },
                ],
                rows: response.data.map((item, index) => {
                    return (response.data[index] = {
                        ...response.data[index],
                        checkbox: (
                            <p>
                                <div className="custom-control custom-checkbox">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id={`checked` + index}
                                    />
                                    <label
                                        className="custom-control-label"
                                        for={`checked` + index}
                                    ></label>
                                </div>
                            </p>
                        ),

                        action: (
                            <>
                                <IonButton
                                    slot="icon-only"
                                    color="warning"
                                    onClick={() => updateAction(item)}
                                >
                                    <IonIcon icon={createOutline} />
                                </IonButton>
                                <IonButton
                                    slot="icon-only"
                                    color="danger"
                                    onClick={() => deleteAction(item)}
                                >
                                    <IonIcon icon={trashOutline} />
                                </IonButton>
                            </>
                        ),
                        number: index + 1,
                    })
                }),
            })
        } catch (error) {
            console.log('error', error)
        }
    }

    const getAllTags = async () => {
        try {
            const response = await api.get('/tags')
            setTags(response.data)
        } catch (error) {
            console.log('error', error)
        }
    }

    useEffect(() => {
        getAllReports()
        getAllTags()
    }, [])

    const deleteAction = (report) => {
        deleteReport(report)
    }

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        })
    }

    const handleChangeSelect = (e) => {
        setSelectedTags(e)
    }

    const updateAction = (report) => {
        getReportTags(report)
        setState(report)
        setUpdate(true)
        setShowModal(true)
    }

    const addReport = () => {
        setShowModal(true)
        setUpdate(false)
        setState({
            name: '',
            description: '',
            tag: '',
        })
        setSelectedTags([])
    }

    const getReportTags = (report) => {
        api.get('/reports/' + report.id + '/tags').then((response) => {
            setSelectedTags(response.data)
        })
    }

    const updateReportTags = (report) => {
        api.put('/reports/' + report.id + '/tags', selectedTags)
    }

    const createReport = () => {
        const data = {
            name: state.name,
            description: state.description,
            tag: state.tag,
            //tags: state.tags,
        }

        api.post('/reports', data).then((res) => {
            updateReportTags(res.data)
            setShowModal(false)
            getAllReports()
        })
    }

    const updateReport = () => {
        const dataUpdate = {
            id: state.id,
            name: state.name,
            description: state.description,
            tag: state.tag,
            //tags: state.tags,
        }

        api.put('/reports/' + state.id, dataUpdate).then((res) => {
            updateReportTags(res.data)
            setShowModal(false)
            getAllReports()
        })
    }

    const deleteReport = (report) => {
        api.delete('/reports/' + report.id).then(() => {
            setReports([])
            getAllReports()
        })
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>LifeDNA | ADMIN | Report Management</title>
            </Helmet>
            <IonPage>
                <Header />
                <IonContent>
                    <IonHeader>
                        <IonToolbar>
                            <IonTitle>Report Management</IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <IonGrid>
                        <IonRow>
                            <IonCol size="12">
                                <IonButton
                                    color="tertiary"
                                    onClick={() => addReport()}
                                >
                                    <IonIcon slot="end" icon={addOutline} />
                                    {'Add New Report'}
                                </IonButton>
                                <MDBDataTableV5
                                    hover
                                    striped
                                    bordered
                                    entriesOptions={[10, 20, 30]}
                                    entries={10}
                                    pagesAmount={10}
                                    data={reports}
                                    pagingTop
                                    searchTop
                                    searchBottom={false}
                                />
                            </IonCol>
                        </IonRow>
                    </IonGrid>

                    <MDBModal isOpen={showModal} fullHeight position="right">
                        <MDBModalHeader>
                            {update === true
                                ? 'Update Report'
                                : 'Add New Report'}
                        </MDBModalHeader>
                        <MDBModalBody>
                            <div className="form-group">
                                <label htmlFor="formGroupExampleInput">
                                    Report Name
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="name"
                                    value={state.name}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">
                                    Description
                                </label>
                                <textarea
                                    className="form-control"
                                    name="description"
                                    type="textarea"
                                    rows="8"
                                    value={state.description}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">
                                    Short Description
                                </label>
                                <textarea
                                    className="form-control"
                                    name="tag"
                                    type="textarea"
                                    rows="4"
                                    value={state.tag}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="formGroupExampleInput">
                                    Tags
                                </label>
                                <CreatableSelect
                                    closeMenuOnSelect={false}
                                    options={tags}
                                    components={animatedComponents}
                                    isMulti
                                    name="tags"
                                    getOptionValue={(option) => option['id']}
                                    getOptionLabel={(option) => option['name']}
                                    isClearable
                                    onChange={handleChangeSelect}
                                    value={selectedTags}
                                    getNewOptionData={(
                                        inputValue,
                                        optionLabel
                                    ) => ({
                                        id: inputValue,
                                        name: optionLabel,
                                    })}
                                    //defaultValue={selectedValue}
                                />
                            </div>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <IonButton
                                color="danger"
                                onClick={() => setShowModal(false)}
                            >
                                Cancel
                            </IonButton>
                            {update === true ? (
                                <IonButton
                                    color="tertiary"
                                    onClick={updateReport}
                                >
                                    Update Changes
                                </IonButton>
                            ) : (
                                <IonButton
                                    color="success"
                                    onClick={createReport}
                                >
                                    Save Changes
                                </IonButton>
                            )}
                        </MDBModalFooter>
                    </MDBModal>
                </IonContent>
            </IonPage>
        </React.Fragment>
    )
}

export default AdminReport
