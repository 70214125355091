import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbreact/dist/css/mdb.css'

import React, { useEffect, useState } from 'react'

import {
    IonButton, IonCheckbox,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon, IonItem, IonLabel,
    IonPage,
    IonRow, IonSearchbar,
    IonTitle,
    IonToolbar,
} from '@ionic/react'
import {addOutline, createOutline, eyeOutline, trashOutline} from 'ionicons/icons'
import {
    MDBCol,
    MDBDataTableV5,
    MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBModalHeader, MDBRow,
} from 'mdbreact'
import Select from 'react-select'

import api from '../Api'
import Header from './admin-header'
import { Helmet } from 'react-helmet'
import {Country, State} from "country-state-city";

const AdminSupport = () => {

    const customerJSON = {
        "profile": {
            "firstName": "Khalid",
            "lastName": "Alaa",
            "biologicalSex": "female",
            "shippingAddress": {
                "addressLine1": "6 Sherif Al Sarsawi",
                "addressLine2": "string",
                "country": "US",
                "city": "Dream Land",
                "state": "HI",
                "zipCode": "090909"
            }
        },
        "accesses": [
            "Skincare Report",
            "Nutrition Report"
        ],
        "dnaUploaded": true,
        "kit": {
            "id": "jdsahjkdhsajdas-djaskhdjkashdjkas-dasjkdhjkashd",
            "sampleId": "89789798799",
            "barCode": "908390482924",
            "status": "registered",
            "trackingNumber": "84723894789327482"
        },
        "reportStatus": "FAILED"
    }


    const [toggle, setToggle] = useState({ 1: false, 2: false, 3: false , 4: false})
    const [show, setShow] = useState(false)
    const [showLoadingIcon, setShowLoadingIcon] = useState(false)
    const [showCustomerInfo,setShowCustomerInfo] = useState(false)
    const [customerSearchEmail,setCustomerSearchEmail] = useState(null)
    const [currentAccesses,setCurrentAccesses] = useState([])
    const [customer,setCustomer] = useState(null)
    const [message, setMessage] = useState({ target: '', type: '', text: '' })
    const [disableState, setdisableState] = useState(false)
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const [customerId, setCustomerId] = useState(null)
    const [countryStateNames, setCountryStateNames] = useState({
        country: null,
        state: null,
    })


    const handleAccessUpdate = () => {

        setShowLoadingIcon(true)
        setdisableState(true)

        api.put(`/customer-service/customers/${customerId}/accesses`, customer.accesses)
            .then(function (response) {
                setShowLoadingIcon(false)
                setdisableState(false)
                setMessage({ target: 'ACCESS', type: 'success', text: 'Customer Accesses Updated' })
            })
            .catch(function (error) {
                setShowLoadingIcon(false)
                setdisableState(false)
                setMessage({ target: 'ACCESS', type: 'danger', text: 'Something Went Wrong Try Again Later' })
            })
    }

    const handleDNAReset = () => {

        setShowLoadingIcon(true)
        setdisableState(true)

        api.delete(`/customer-service/customers/${customerId}/dna`, customer.accesses)
            .then(function (response) {
                setShowLoadingIcon(false)
                setdisableState(false)
                setMessage({ target: 'DNA', type: 'success', text: 'Customer DNA Deleted' })
            })
            .catch(function (error) {
                setShowLoadingIcon(false)
                setdisableState(false)
                setMessage({ target: 'DNA', type: 'danger', text: 'Something Went Wrong Try Again Later' })
            })
    }


    const getAccessesFromAPI = () => {
        api.get(`/acceses`)
            .then(function (response) {
                setCurrentAccesses(response.data)
            })
            .catch(function (error) {
                setCurrentAccesses([])
            })
    }

    const handleSubmitSearch = () => {
        setShowLoadingIcon(true)
        if(customerSearchEmail){
            setMessage({ target: '', type: '', text: '' })
            getCustomerInfo()
        }else{
            setMessage({ target: 'SEARCH', type: 'danger', text: 'Email cannot be empty' })
            setShowLoadingIcon(false)
        }
    }

    const handlePersonalInfoChange = (e) => {
        const new_customer = { ...customer }
        new_customer.profile[e.target.id] = e.target.value
        setCustomer(new_customer)
    }

    const handleShippingAddressChange = (e) => {
        if (e.target.id === 'country') {
            setStates(State.getStatesOfCountry(e.target.value))
        }
        const new_customer = { ...customer }
        new_customer.profile.shippingAddress[e.target.id] = e.target.value
        setCustomer(new_customer)
    }

    const handleAccessesChange = (e) => {
        let newCustomer = {...customer}
        let newCustomerAccesses =[...customer.accesses]
        if(e.detail.checked){
            if(!newCustomerAccesses.includes(e.detail.value)){
                newCustomerAccesses.push(e.detail.value)
                newCustomer.accesses = newCustomerAccesses
                setCustomer(newCustomer)
            }
        }else{
            const index = newCustomerAccesses.indexOf(e.detail.value);
            if (index > -1) {
                newCustomerAccesses.splice(index, 1);
                newCustomer.accesses = newCustomerAccesses
                setCustomer(newCustomer)
            }
        }

    }

    const handlePersonalInfoSubmit = () => {

        setShowLoadingIcon(true)
        setdisableState(true)

        const customerProfile = { ...customer.profile}
        delete customerProfile.shippingAddress

        api.put(`/customer-service/customers/${customerId}/profile`, customerProfile)
            .then(function (response) {
                setShowLoadingIcon(false)
                setdisableState(false)
                setMessage({ target: 'PERSONAL', type: 'success', text: 'Customer Info Updated' })
            })
            .catch(function (error) {
                setShowLoadingIcon(false)
                setdisableState(false)
                setMessage({ target: 'PERSONAL', type: 'danger', text: 'Something Went Wrong Try Again Later' })
            })
    }

    const submitShippingForm = () => {

        setShowLoadingIcon(true)
        setdisableState(true)

        api.put(`/customer-service/customers/${customerId}/address`, customer.profile.shippingAddress)
            .then(function (response) {
                setShowLoadingIcon(false)
                setdisableState(false)
                setMessage({ target: 'SHIPPING', type: 'success', text: 'Customer Address Updated' })
            })
            .catch(function (error) {
                setShowLoadingIcon(false)
                setdisableState(false)
                setMessage({ target: 'SHIPPING', type: 'danger', text: 'Something Went Wrong Try Again Later' })
            })
    }


    const getCustomerInfo = () => {
        api.get(`/customer-service/customers`,{ params: {email: customerSearchEmail}})
            .then(function (response) {
                console.log(response)
                let fetchedcustomerId = response.data[0].id
                setCustomerId(fetchedcustomerId)
                api.get(`/customer-service/customers/${fetchedcustomerId}`)
                    .then(function (response) {
                        console.log(response)
                        setCustomer(response.data)
                    })
                    .catch(function (error) {
                        console.log(error.response.data);
                        setCustomer(null)
                    })
            })
            .catch(function (error) {
                console.log(error.response.data);
                setCurrentAccesses([])
            })
    }

    const handleSearchEmailChange = (e) => {
        setCustomerSearchEmail(e.target.value)
    }

    const toggleEdit = (index) => {
        let newToggle = { ...toggle }
        newToggle[index] = !newToggle[index]
        setToggle(newToggle)
    }

    const submitForm = () => {
        setShow(true)
    }


    useEffect(() => {
        setCountries(Country.getAllCountries())
        setStates(State.getStatesOfCountry('US'))
        getAccessesFromAPI();
    } , [])

    return (
        <React.Fragment>
            <Helmet>
                <title>LifeDNA | ADMIN | Customer Support Management</title>
            </Helmet>
            <IonPage>
                <Header />
                <IonContent>
                    <IonGrid fixed={true} className="app-body">
                        {message.target == "SEARCH" ? (
                            <IonRow>
                                <IonCol size="12">
                                    <div className={`form-alert ${message.type}`}>
                                        {message.text}
                                    </div>
                                </IonCol>
                            </IonRow>
                        ) : null}

                        <IonRow>
                            <IonCol
                                size-xs="12"
                                size-sm="12"
                                size-md="12"
                                size-xl="12"
                            >
                                <IonSearchbar
                                    placeholder="Search by Email"
                                    animated
                                    onIonChange={handleSearchEmailChange}
                                ></IonSearchbar>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol
                                size="12"
                                size-sm="12"
                                size-md="6"
                                size-xl="8"
                                className="ion-hide-md-down"
                            ></IonCol>
                            <IonCol
                                size="12"
                                size-sm="12"
                                size-md="3"
                                size-xl="2"
                            >
                                <IonButton
                                    expand="block"
                                    color={'tertiary'}
                                    className="prod-btn fz-14"
                                    style={{visibility: "hidden"}}
                                >
                                    <b>Cancel
                                    </b>
                                </IonButton>
                            </IonCol>
                            <IonCol
                                size="12"
                                size-sm="12"
                                size-md="3"
                                size-xl="2"
                            >
                                <IonButton
                                    expand="block"
                                    color="primary"
                                    className="prod-btn fz-14"
                                    type="button"
                                    onClick={handleSubmitSearch}
                                >
                                    <b>{' '}
                                        {showLoadingIcon ? (
                                            <i className="fa fa-spinner fa-pulse fa-2x fa-fw"></i>
                                        ) : (
                                            'Search'
                                        )}{' '}</b>
                                </IonButton>
                            </IonCol>
                        </IonRow>

                        {showCustomerInfo ? (
                            <IonRow className="ion-margin-top">
                                <IonCol size="12">
                                    <form>
                                        <div className="forms-holder ion-margin-bottom">
                                            <IonRow className="row-header">
                                                <IonCol size="12">
                                                    <h2>
                                                        <b>Basic Information</b>
                                                    </h2>
                                                    <hr className="form-border" />
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol
                                                    size="12"
                                                    size-sm="12"
                                                    size-md="6"
                                                    size-xl="6"
                                                >
                                                    <div className="form-group">
                                                        <label className="form-label fz-16">
                                                            First Name
                                                        </label>
                                                        <input
                                                            id="firstName"
                                                            name="firstName"
                                                            type="text"
                                                            placeholder="Jhon"
                                                            className={`form-control ${
                                                                toggle[1]
                                                                    ? ''
                                                                    : 'disabled-input'
                                                            }`}
                                                            readOnly={
                                                                toggle[1]
                                                                    ? ''
                                                                    : 'readOnly'
                                                            }
                                                            value={customer.profile.firstName}
                                                            onChange={handlePersonalInfoChange}
                                                        />
                                                    </div>
                                                </IonCol>
                                                <IonCol
                                                    size="12"
                                                    size-sm="12"
                                                    size-md="6"
                                                    size-xl="6"
                                                >
                                                    <div className="form-group">
                                                        <label className="form-label fz-16">
                                                            Last Name
                                                        </label>
                                                        <input
                                                            id="lastName"
                                                            name="lastName"
                                                            type="text"
                                                            placeholder="Doe"
                                                            className={`form-control ${
                                                                toggle[1]
                                                                    ? ''
                                                                    : 'disabled-input'
                                                            }`}
                                                            readOnly={
                                                                toggle[1]
                                                                    ? ''
                                                                    : 'readOnly'
                                                            }
                                                            value={customer.profile.lastName}
                                                            onChange={handlePersonalInfoChange}
                                                        />
                                                    </div>
                                                </IonCol>
                                                <IonCol
                                                    size="12"
                                                    size-sm="12"
                                                    size-md="6"
                                                    size-xl="6"
                                                ></IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol
                                                    size="12"
                                                    size-sm="12"
                                                    size-md="6"
                                                    size-xl="6"
                                                >
                                                    <div className="form-group">
                                                        <label className="form-label fz-16">
                                                            Gender
                                                        </label>
                                                        <select
                                                            id="biologicalSex"
                                                            name="biologicalSex"
                                                            placeholder="Gender"
                                                            className={`form-control ${
                                                                toggle[1]
                                                                    ? ''
                                                                    : 'disabled-input'
                                                            }`}
                                                            onChange={handlePersonalInfoChange}
                                                        >
                                                            <option  value="male" selected={customer.profile.biologicalSex === "male" ? true : false } >
                                                                Male
                                                            </option>
                                                            <option  value="female" selected={customer.profile.biologicalSex === "female" ? true : false }>
                                                                Female
                                                            </option>
                                                        </select>
                                                    </div>
                                                </IonCol>
                                            </IonRow>
                                            {message.target == "PERSONAL" ? (
                                                <IonRow>
                                                    <IonCol size="12">
                                                        <div className={`form-alert ${message.type}`}>
                                                            {message.text}
                                                        </div>
                                                    </IonCol>
                                                </IonRow>
                                            ) : null}
                                            <IonRow>
                                                <IonCol
                                                    size="12"
                                                    size-sm="12"
                                                    size-md="6"
                                                    size-xl="8"
                                                    className="ion-hide-md-down"
                                                ></IonCol>
                                                <IonCol
                                                    size="12"
                                                    size-sm="12"
                                                    size-md="3"
                                                    size-xl="2"
                                                >
                                                    <IonButton
                                                        expand="block"
                                                        color={
                                                            toggle[1]
                                                                ? 'danger'
                                                                : 'tertiary'
                                                        }
                                                        className="prod-btn fz-14"
                                                        onClick={() =>
                                                            toggleEdit(1)
                                                        }
                                                    >
                                                        <b>
                                                            {' '}
                                                            {toggle[1]
                                                                ? 'Cancel'
                                                                : 'Edit'}
                                                        </b>
                                                    </IonButton>
                                                </IonCol>
                                                <IonCol
                                                    size="12"
                                                    size-sm="12"
                                                    size-md="3"
                                                    size-xl="2"
                                                >
                                                    <IonButton
                                                        expand="block"
                                                        color="primary"
                                                        className="prod-btn fz-14"
                                                        type="button"
                                                        onClick={handlePersonalInfoSubmit}
                                                        disabled={disableState || toggle[1] == false ? true : false}
                                                    >
                                                        <b>{showLoadingIcon ? (<i className="fa fa-spinner fa-pulse fa-2x fa-fw"></i>) : ('Save')}</b>
                                                    </IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </div>
                                    </form>

                                    <form>
                                        <div className="forms-holder ion-margin-bottom">
                                            <IonRow className="row-header">
                                                <IonCol size="12">
                                                    <h2>
                                                        <b>Shipping Information</b>
                                                    </h2>
                                                    <hr className="form-border" />
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol
                                                    size="12"
                                                    size-sm="12"
                                                    size-md="6"
                                                    size-xl="6"
                                                >
                                                    <div className="form-group">
                                                        <label className="form-label fz-16">
                                                            Address
                                                        </label>
                                                        <input
                                                            id="addressLine1"
                                                            name="addressLine1"
                                                            type="text"
                                                            placeholder="1050 Queen Street, #100, Honolulu, HI 96814"
                                                            className={`form-control ${
                                                                toggle[2]
                                                                    ? ''
                                                                    : 'disabled-input'
                                                            }`}
                                                            readOnly={
                                                                toggle[2]
                                                                    ? ''
                                                                    : 'readOnly'
                                                            }
                                                            value={customer.profile.shippingAddress.addressLine1}
                                                            onChange={
                                                                handleShippingAddressChange
                                                            }
                                                        />
                                                    </div>
                                                </IonCol>
                                                <IonCol
                                                    size="12"
                                                    size-sm="12"
                                                    size-md="6"
                                                    size-xl="6"
                                                >
                                                    <div className="form-group">
                                                        <label className="form-label fz-16">
                                                            City
                                                        </label>
                                                        <input
                                                            id="city"
                                                            name="city"
                                                            type="text"
                                                            placeholder="Honolulu"
                                                            className={`form-control ${
                                                                toggle[2]
                                                                    ? ''
                                                                    : 'disabled-input'
                                                            }`}
                                                            readOnly={
                                                                toggle[2]
                                                                    ? ''
                                                                    : 'readOnly'
                                                            }
                                                            value={customer.profile.shippingAddress.city}
                                                            onChange={
                                                                handleShippingAddressChange
                                                            }
                                                        />
                                                    </div>
                                                </IonCol>
                                                <IonCol
                                                    size="12"
                                                    size-sm="12"
                                                    size-md="6"
                                                    size-xl="6"
                                                >
                                                    <div className="form-group">
                                                        <label className="form-label fz-16">
                                                            Country
                                                        </label>
                                                        <select
                                                            onChange={
                                                                handleShippingAddressChange
                                                            }
                                                            id="country"
                                                            name="country"
                                                            placeholder="United States"
                                                            className={`form-control ${
                                                                toggle[2]
                                                                    ? ''
                                                                    : 'disabled-input'
                                                            }`}
                                                        >
                                                            {toggle[2] ? (
                                                                <React.Fragment>
                                                                    {countries.map(
                                                                        (
                                                                            item,
                                                                            index
                                                                        ) => {
                                                                            if (
                                                                                item
                                                                            ) {
                                                                                return (
                                                                                    <option
                                                                                        value={
                                                                                            item.isoCode
                                                                                        }
                                                                                        key={
                                                                                            item.isoCode
                                                                                        }
                                                                                        selected={
                                                                                            customer.profile
                                                                                                .shippingAddress
                                                                                                .country ===
                                                                                            item.isoCode
                                                                                                ? true
                                                                                                : false
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            item.name
                                                                                        }
                                                                                    </option>
                                                                                )
                                                                            }
                                                                        }
                                                                    )}
                                                                </React.Fragment>
                                                            ) : (
                                                                <React.Fragment>
                                                                    <option
                                                                        value="0"
                                                                        checked=""
                                                                        hidden=""
                                                                    >
                                                                        {countryStateNames.country !==
                                                                        undefined &&
                                                                        countryStateNames.country !==
                                                                        null
                                                                            ? countryStateNames.country
                                                                            : 'Click edit to show countries'}
                                                                    </option>
                                                                </React.Fragment>
                                                            )}
                                                        </select>
                                                    </div>
                                                </IonCol>
                                                <IonCol
                                                    size="12"
                                                    size-sm="12"
                                                    size-md="6"
                                                    size-xl="6"
                                                >
                                                    <div className="form-group">
                                                        <label className="form-label fz-16">
                                                            Zip/Postal Code
                                                        </label>
                                                        <input
                                                            id="zipCode"
                                                            name="zipCode"
                                                            type="text"
                                                            placeholder="96814"
                                                            className={`form-control ${
                                                                toggle[2]
                                                                    ? ''
                                                                    : 'disabled-input'
                                                            }`}
                                                            readOnly={
                                                                toggle[2]
                                                                    ? ''
                                                                    : 'readOnly'
                                                            }
                                                            value={customer.profile.shippingAddress.zipCode}
                                                            onChange={
                                                                handleShippingAddressChange
                                                            }
                                                        />
                                                    </div>
                                                </IonCol>
                                                <IonCol
                                                    size="12"
                                                    size-sm="12"
                                                    size-md="12"
                                                    size-xl="12"
                                                >
                                                    <div className="form-group">
                                                        <label className="form-label fz-16">
                                                            State/Province
                                                        </label>
                                                        <select
                                                            onChange={
                                                                handleShippingAddressChange
                                                            }
                                                            id="state"
                                                            name="state"
                                                            className={`form-control ${
                                                                toggle[2]
                                                                    ? ''
                                                                    : 'disabled-input'
                                                            }`}
                                                        >
                                                            {toggle[2] ? (
                                                                <React.Fragment>
                                                                    {states.map(
                                                                        (
                                                                            item,
                                                                            index
                                                                        ) => {
                                                                            if (
                                                                                item
                                                                            ) {
                                                                                return (
                                                                                    <option
                                                                                        value={
                                                                                            item.isoCode
                                                                                        }
                                                                                        key={
                                                                                            item.isoCode
                                                                                        }
                                                                                        selected={
                                                                                            customer.profile
                                                                                                .shippingAddress
                                                                                                .state ===
                                                                                            item.isoCode
                                                                                                ? true
                                                                                                : false
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            item.name
                                                                                        }
                                                                                    </option>
                                                                                )
                                                                            }
                                                                        }
                                                                    )}
                                                                </React.Fragment>
                                                            ) : (
                                                                <React.Fragment>
                                                                    <option
                                                                        value="0"
                                                                        checked=""
                                                                        hidden=""
                                                                    >
                                                                        {countryStateNames.state !==
                                                                        undefined &&
                                                                        countryStateNames.state !==
                                                                        null
                                                                            ? countryStateNames.state
                                                                            : 'Click edit to show states'}
                                                                    </option>
                                                                </React.Fragment>
                                                            )}
                                                        </select>
                                                    </div>
                                                </IonCol>
                                            </IonRow>
                                            {message.target == "SHIPPING" ? (
                                                <IonRow>
                                                    <IonCol size="12">
                                                        <div className={`form-alert ${message.type}`}>
                                                            {message.text}
                                                        </div>
                                                    </IonCol>
                                                </IonRow>
                                            ) : null}
                                            <IonRow>
                                                <IonCol
                                                    size="12"
                                                    size-sm="12"
                                                    size-md="6"
                                                    size-xl="8"
                                                    className="ion-hide-md-down"
                                                ></IonCol>
                                                <IonCol
                                                    size="12"
                                                    size-sm="12"
                                                    size-md="3"
                                                    size-xl="2"
                                                >
                                                    <IonButton
                                                        expand="block"
                                                        color={
                                                            toggle[2]
                                                                ? 'danger'
                                                                : 'tertiary'
                                                        }
                                                        className="prod-btn fz-14"
                                                        onClick={() =>
                                                            toggleEdit(2)
                                                        }
                                                    >
                                                        <b>
                                                            {toggle[2]
                                                                ? 'Cancel'
                                                                : 'Edit'}
                                                        </b>
                                                    </IonButton>
                                                </IonCol>
                                                <IonCol
                                                    size="12"
                                                    size-sm="12"
                                                    size-md="3"
                                                    size-xl="2"
                                                >
                                                    <IonButton
                                                        expand="block"
                                                        color="primary"
                                                        className="prod-btn fz-14"
                                                        type="button"
                                                        disabled={disableState || toggle[2] == false ? true : false}
                                                        onClick={submitShippingForm}
                                                    >
                                                        <b>{showLoadingIcon ? (<i className="fa fa-spinner fa-pulse fa-2x fa-fw"></i>) : ('Save')}</b>
                                                    </IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </div>
                                    </form>

                                    <form>
                                        <div className="forms-holder ion-margin-bottom">
                                            <IonRow className="row-header">
                                                <IonCol size="12">
                                                    <h2>
                                                        <b>Customer Accesses</b>
                                                    </h2>
                                                    <hr className="form-border" />
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                {currentAccesses.map((item, i) => (
                                                    <IonCol
                                                        size="12"
                                                        size-sm="12"
                                                        size-md="6"
                                                        size-xl="3"
                                                    >
                                                        <IonItem key={item.id}>
                                                            <IonLabel>{item.name}</IonLabel>
                                                            <IonCheckbox slot="end" value={item.name} checked={customer.accesses.includes(item.name) ? true : false} onIonChange={handleAccessesChange} disabled={toggle[3] ? false : true}  />
                                                        </IonItem>
                                                    </IonCol>
                                                ))}
                                            </IonRow>

                                            {message.target == "ACCESS" ? (
                                                <IonRow>
                                                    <IonCol size="12">
                                                        <div className={`form-alert ${message.type}`}>
                                                            {message.text}
                                                        </div>
                                                    </IonCol>
                                                </IonRow>
                                            ) : null}

                                            <IonRow>
                                                <IonCol
                                                    size="12"
                                                    size-sm="12"
                                                    size-md="6"
                                                    size-xl="8"
                                                    className="ion-hide-md-down"
                                                ></IonCol>
                                                <IonCol
                                                    size="12"
                                                    size-sm="12"
                                                    size-md="3"
                                                    size-xl="2"
                                                >
                                                    <IonButton
                                                        expand="block"
                                                        color={
                                                            toggle[3]
                                                                ? 'danger'
                                                                : 'tertiary'
                                                        }
                                                        className="prod-btn fz-14"
                                                        onClick={() =>
                                                            toggleEdit(3)
                                                        }
                                                    >
                                                        <b>
                                                            {toggle[3]
                                                                ? 'Cancel'
                                                                : 'Edit'}
                                                        </b>
                                                    </IonButton>
                                                </IonCol>
                                                <IonCol
                                                    size="12"
                                                    size-sm="12"
                                                    size-md="3"
                                                    size-xl="2"
                                                >
                                                    <IonButton
                                                        expand="block"
                                                        color="primary"
                                                        className="prod-btn fz-14"
                                                        type="button"
                                                        disabled={disableState || toggle[3] == false ? true : false}
                                                        onClick={handleAccessUpdate}

                                                    >
                                                        <b>{showLoadingIcon ? (<i className="fa fa-spinner fa-pulse fa-2x fa-fw"></i>) : ('Save')}</b>
                                                    </IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </div>
                                    </form>


                                    <form>
                                        <div className="forms-holder ion-margin-bottom">
                                            <IonRow className="row-header">
                                                <IonCol size="12">
                                                    <h2>
                                                        <b>DNA Information</b>
                                                    </h2>
                                                    <hr className="form-border" />
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol
                                                    size="12"
                                                    size-sm="12"
                                                    size-md="6"
                                                    size-xl="6"
                                                >
                                                    <div className="form-group">
                                                        <label className="form-label fz-16">
                                                            DNA Uploaded
                                                        </label>
                                                        <input
                                                            id="dnaUploaded"
                                                            name="dnaUploaded"
                                                            type="text"
                                                            placeholder="Jhon"
                                                            className={`form-control disabled-input` }
                                                            readOnly={'readOnly'}
                                                            value={customer.dnaUploaded}
                                                        />
                                                    </div>
                                                </IonCol>
                                                <IonCol
                                                    size="12"
                                                    size-sm="12"
                                                    size-md="6"
                                                    size-xl="6"
                                                >
                                                    <div className="form-group">
                                                        <label className="form-label fz-16">
                                                            Report Status
                                                        </label>
                                                        <input
                                                            id="lastName"
                                                            name="lastName"
                                                            type="text"
                                                            placeholder="Doe"
                                                            className={`form-control disabled-input`}
                                                            readOnly={'readOnly'}
                                                            value={customer.reportStatus}
                                                        />
                                                    </div>
                                                </IonCol>
                                                <IonCol
                                                    size="12"
                                                    size-sm="12"
                                                    size-md="6"
                                                    size-xl="6"
                                                ></IonCol>
                                            </IonRow>
                                            {message.target == "DNA" ? (
                                                <IonRow>
                                                    <IonCol size="12">
                                                        <div className={`form-alert ${message.type}`}>
                                                            {message.text}
                                                        </div>
                                                    </IonCol>
                                                </IonRow>
                                            ) : null}
                                            {customer.dnaUploaded === true ? (
                                                <IonRow>
                                                    <IonCol
                                                        size="12"
                                                        size-sm="12"
                                                        size-md="6"
                                                        size-xl="8"
                                                        className="ion-hide-md-down"
                                                    ></IonCol>
                                                    <IonCol
                                                        size="12"
                                                        size-sm="12"
                                                        size-md="3"
                                                        size-xl="2"
                                                    >
                                                        <IonButton
                                                            expand="block"
                                                            color={
                                                                toggle[4]
                                                                    ? 'danger'
                                                                    : 'tertiary'
                                                            }
                                                            className="prod-btn fz-14"
                                                            onClick={() =>
                                                                toggleEdit(4)
                                                            }
                                                        >
                                                            <b>
                                                                {' '}
                                                                {toggle[4]
                                                                    ? 'Cancel'
                                                                    : 'Edit'}
                                                            </b>
                                                        </IonButton>
                                                    </IonCol>
                                                    <IonCol
                                                        size="12"
                                                        size-sm="12"
                                                        size-md="3"
                                                        size-xl="2"
                                                    >
                                                        <IonButton
                                                            expand="block"
                                                            color="danger"
                                                            className="prod-btn fz-14"
                                                            type="button"
                                                            onClick={handleDNAReset}
                                                            disabled={disableState || toggle[4] == false ? true : false}
                                                        >
                                                            <b>{showLoadingIcon ? (<i className="fa fa-spinner fa-pulse fa-2x fa-fw"></i>) : ('Reset DNA')}</b>
                                                        </IonButton>
                                                    </IonCol>
                                                </IonRow>
                                            ) : null}
                                        </div>
                                    </form>

                                    {customer.kit !== null ? (
                                        <form>
                                            <div className="forms-holder ion-margin-bottom">
                                                <IonRow className="row-header">
                                                    <IonCol size="12">
                                                        <h2>
                                                            <b>Kit Information</b>
                                                        </h2>
                                                        <hr className="form-border" />
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol
                                                        size="12"
                                                        size-sm="12"
                                                        size-md="6"
                                                        size-xl="6"
                                                    >
                                                        <div className="form-group">
                                                            <label className="form-label fz-16">
                                                                SampleID
                                                            </label>
                                                            <input
                                                                id="sampleId"
                                                                name="sampleId"
                                                                type="text"
                                                                placeholder="Jhon"
                                                                className={`form-control disabled-input` }
                                                                readOnly={'readOnly'}
                                                                value={customer.kit.sampleId}
                                                            />
                                                        </div>
                                                    </IonCol>
                                                    <IonCol
                                                        size="12"
                                                        size-sm="12"
                                                        size-md="6"
                                                        size-xl="6"
                                                    >
                                                        <div className="form-group">
                                                            <label className="form-label fz-16">
                                                                Barcode
                                                            </label>
                                                            <input
                                                                id="barCode"
                                                                name="barCode"
                                                                type="text"
                                                                placeholder="Doe"
                                                                className={`form-control disabled-input`}
                                                                readOnly={'readOnly'}
                                                                value={customer.kit.barCode}
                                                            />
                                                        </div>
                                                    </IonCol>
                                                    <IonCol
                                                        size="12"
                                                        size-sm="12"
                                                        size-md="6"
                                                        size-xl="6"
                                                    ></IonCol>
                                                </IonRow>
                                                <IonRow>
                                                    <IonCol
                                                        size="12"
                                                        size-sm="12"
                                                        size-md="6"
                                                        size-xl="6"
                                                    >
                                                        <div className="form-group">
                                                            <label className="form-label fz-16">
                                                                Status
                                                            </label>
                                                            <input
                                                                id="kitStatus"
                                                                name="kitStatus"
                                                                type="text"
                                                                placeholder="Jhon"
                                                                className={`form-control disabled-input` }
                                                                readOnly={'readOnly'}
                                                                value={customer.kit.status}
                                                            />
                                                        </div>
                                                    </IonCol>
                                                    <IonCol
                                                        size="12"
                                                        size-sm="12"
                                                        size-md="6"
                                                        size-xl="6"
                                                    >
                                                        <div className="form-group">
                                                            <label className="form-label fz-16">
                                                                Tracking Number
                                                            </label>
                                                            <input
                                                                id="trackingNumber"
                                                                name="trackingNumber"
                                                                type="text"
                                                                placeholder="Doe"
                                                                className={`form-control disabled-input`}
                                                                readOnly={'readOnly'}
                                                                value={customer.kit.trackingNumber}
                                                            />
                                                        </div>
                                                    </IonCol>
                                                    <IonCol
                                                        size="12"
                                                        size-sm="12"
                                                        size-md="6"
                                                        size-xl="6"
                                                    ></IonCol>
                                                </IonRow>
                                            </div>
                                        </form>
                                    ): null}

                                </IonCol>
                            </IonRow>
                        ) : null}

                    </IonGrid>
                </IonContent>
            </IonPage>
        </React.Fragment>
    )
}

export default AdminSupport
