import React, { useState, useEffect } from 'react'

import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonCol,
    IonContent,
    IonGrid,
    IonRow,
    IonButton,
    IonIcon,
} from '@ionic/react'
import { trashOutline, addOutline, createOutline } from 'ionicons/icons'
import {
    MDBDataTableV5,
    MDBModal,
    MDBModalHeader,
    MDBModalBody,
    MDBModalFooter,
} from 'mdbreact'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbreact/dist/css/mdb.css'
import { Helmet } from 'react-helmet'
import makeAnimated from 'react-select/animated'
import CreatableSelect from 'react-select/creatable'

import api from '../Api'
import Header from './admin-header'

const animatedComponents = makeAnimated()

const AdminTrait = (props) => {
    const [showModal, setShowModal] = useState(false)
    const [update, setUpdate] = useState(false)
    const [selectedTags, setSelectedTags] = useState([])
    const [traits, setTraits] = useState([])
    const [tags, setTags] = useState([])
    const [state, setState] = useState({
        id: '',
        name: '',
        stars: '',
        description: '',
        tags: [],
    })

    const getAllTraits = async () => {
        try {
            const response = await api.get('/traits')
            setTraits({
                columns: [
                    {
                        label: '',
                        field: 'checkbox',
                        width: 30,
                    },
                    {
                        label: '#',
                        field: 'number',
                        width: 30,
                    },
                    {
                        label: 'Name',
                        field: 'name',
                        width: 200,
                        attributes: {
                            'aria-controls': 'DataTable',
                            'aria-label': 'Name',
                        },
                    },

                    {
                        label: 'Confidence',
                        field: 'stars',
                        width: 300,
                    },
                    {
                        label: 'Details',
                        field: 'description',
                        width: 300,
                    },
                    {
                        label: 'Tags',
                        field: 'tags',
                        width: 300,
                    },
                    {
                        label: '',
                        field: 'action',
                        width: 30,
                        sort: 'disabled',
                    },
                ],
                rows: response.data.map((item, index) => {
                    return (response.data[index] = {
                        ...response.data[index],
                        checkbox: (
                            <p>
                                <div className="custom-control custom-checkbox">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id={`checked` + index}
                                    />
                                    <label
                                        className="custom-control-label"
                                        for={`checked` + index}
                                    ></label>
                                </div>
                            </p>
                        ),
                        action: (
                            <>
                                <IonButton
                                    slot="icon-only"
                                    color="primary"
                                    onClick={() =>
                                        props.history.push(
                                            '/admin/traits/' + item.id
                                        )
                                    }
                                >
                                    <IonIcon icon={createOutline} />
                                </IonButton>
                                <IonButton
                                    slot="icon-only"
                                    color="warning"
                                    onClick={() => updateAction(item)}
                                >
                                    <IonIcon icon={createOutline} />
                                </IonButton>
                                <IonButton
                                    disabled
                                    slot="icon-only"
                                    color="danger"
                                    onClick={() => deleteAction(item)}
                                >
                                    <IonIcon icon={trashOutline} />
                                </IonButton>
                            </>
                        ),
                        number: index + 1,
                    })
                }),
            })
        } catch (error) {
            console.log('error', error)
        }
    }

    const getAllTags = async () => {
        try {
            const response = await api.get('/tags')
            setTags(response.data)
        } catch (error) {
            console.log('error', error)
        }
    }

    useEffect(() => {
        getAllTraits()
        getAllTags()
    }, [])

    const deleteAction = (trait) => {
        //window.location.reload(false)
        console.log('deleteTrait')
    }

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        })
    }

    const handleChangeSelect = (e) => {
        setSelectedTags(e)
    }

    const updateAction = (trait) => {
        getTraitTags(trait)
        setState(trait)
        setUpdate(true)
        setShowModal(true)
    }

    const addTrait = () => {
        setShowModal(true)
        setUpdate(false)
        setState({
            name: '',
            stars: '',
            description: '',
        })
        setSelectedTags([])
    }

    const getTraitTags = (trait) => {
        api.get('/traits/' + trait.id + '/tags').then((res) => {
            setSelectedTags(res.data)
        })
    }

    const updateTraitTags = (trait) => {
        api.put('/traits/' + trait.id + '/tags', selectedTags)
    }

    const createTrait = () => {
        const data = {
            name: state.name,
            stars: state.stars,
            description: state.description,
        }

        api.post('/traits', data).then((response) =>
            console.log(response.data.id)
        )
    }

    const updateTrait = () => {
        const dataUpdate = {
            id: state.id,
            name: state.name,
            stars: state.stars,
            caption: null,
            about: null,
            actionable: null,
            description: state.description,
            snps: [],
            tertiles: [],
            traitReferences: [],
            sexSpecific: null,
            traitSpecific: null,
        }

        updateTraitTags(state)
        setShowModal(false)

        // api.put('/traits/' + state.id, dataUpdate).then((response) =>
        //     console.log(response.data.id)
        // )
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>LifeDNA | ADMIN | Trait Management</title>
            </Helmet>
            <IonPage>
                <Header />
                <IonContent>
                    <IonHeader>
                        <IonToolbar>
                            <IonTitle>Trait Management</IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <IonGrid>
                        <IonRow>
                            <IonCol size="12">
                                <IonButton
                                    color="tertiary"
                                    onClick={() => addTrait()}
                                >
                                    <IonIcon slot="end" icon={addOutline} />
                                    {'Add New Trait'}
                                </IonButton>
                                <MDBDataTableV5
                                    hover
                                    striped
                                    bordered
                                    entriesOptions={[10, 20, 30]}
                                    entries={10}
                                    pagesAmount={10}
                                    data={traits}
                                    pagingTop
                                    searchTop
                                    searchBottom={false}
                                />
                            </IonCol>
                        </IonRow>
                    </IonGrid>

                    <MDBModal isOpen={showModal} fullHeight position="right">
                        <MDBModalHeader>
                            {update === true ? 'Update Trait' : 'Add New Trait'}
                        </MDBModalHeader>
                        <MDBModalBody>
                            <div className="form-group">
                                <label htmlFor="formGroupExampleInput">
                                    Trait Name
                                </label>
                                <input
                                    disabled
                                    type="text"
                                    className="form-control"
                                    name="name"
                                    value={state.name}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="formGroupExampleInput">
                                    Confidence
                                </label>
                                <input
                                    disabled
                                    type="text"
                                    className="form-control"
                                    value={state.stars}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">
                                    Description
                                </label>
                                <textarea
                                    disabled
                                    className="form-control"
                                    name="descriptions"
                                    type="textarea"
                                    rows="8"
                                    value={state.description}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="formGroupExampleInput">
                                    Tags
                                </label>
                                <CreatableSelect
                                    closeMenuOnSelect={false}
                                    options={tags}
                                    components={animatedComponents}
                                    isMulti
                                    name="tags"
                                    getOptionValue={(option) => option['id']}
                                    getOptionLabel={(option) => option['name']}
                                    isClearable
                                    onChange={handleChangeSelect}
                                    value={selectedTags}
                                    getNewOptionData={(
                                        inputValue,
                                        optionLabel
                                    ) => ({
                                        id: inputValue,
                                        name: optionLabel,
                                    })}
                                    //defaultValue={selectedValue}
                                />
                            </div>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <IonButton
                                color="danger"
                                onClick={() => setShowModal(false)}
                            >
                                Cancel
                            </IonButton>
                            {update === true ? (
                                <IonButton
                                    color="tertiary"
                                    onClick={updateTrait}
                                >
                                    Update Changes
                                </IonButton>
                            ) : (
                                <IonButton
                                    color="success"
                                    onClick={createTrait}
                                >
                                    Save Changes
                                </IonButton>
                            )}
                        </MDBModalFooter>
                    </MDBModal>
                </IonContent>
            </IonPage>
        </React.Fragment>
    )
}

export default AdminTrait
