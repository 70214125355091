import { default as React, useEffect, useState } from 'react'
import {
    IonButton,
    IonCheckbox,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
} from '@ionic/react'
import axios from 'axios'
import { keyOutline, mailOutline } from 'ionicons/icons'

import api from '../Api'
import Header from './admin-header'
import { Helmet } from 'react-helmet'

const Account = () => {
    const [text, setText] = useState({
        email: '',
        password: '',
    })

    const handleChange = (e) => {
        setText({
            ...text,
            [e.target.name]: e.target.value,
        })
    }

    const Login = async (e) => {
        e.preventDefault()
        try {
            // const response = await axios({
            //     method: 'post',
            //     url: 'https://gyuv8srzvhlqlrsj.lifedna.com/auth/jwt/login',
            //     params: {
            //         grant_type: '',
            //         username: text.email,
            //         password: text.password,
            //         scope: '',
            //         client_id: '',
            //         client_secret: '',
            //     },
            // })
            await api
                .post(process.env.REACT_APP_ADMIN_API_LOGIN, {
                    params: {
                        grant_type: '',
                        username: text.email,
                        password: text.password,
                        scope: '',
                        client_id: '',
                        client_secret: '',
                    },
                })
                .then((response) => {
                    console.log(response)
                })

            // console.log(response)
        } catch (error) {
            console.log('error', error)
        }
    }

    const register = (e) => {
        e.preventDefault()
        try {
            const data = {
                email: 'user@example.com',
                password: 'string',
                is_active: true,
                is_superuser: false,
                is_verified: false,
            }
            axios.post('/auth/register', data).then((res) => {
                console.log(res)
            })
        } catch (error) {
            console.log('error', error)
        }
    }

    const GmailLogin = async () => {
        console.log('API ', process.env.REACT_APP_GOOGLE_AUTHORIZATION)
        await api
            .get(process.env.REACT_APP_GOOGLE_AUTHORIZATION)
            .then((response) => {
                window.location = response.data.authorization_url
            })
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>LifeDNA | ADMIN | Admin Login</title>
            </Helmet>
            <IonPage>
                <Header />
                <IonContent>
                    <IonHeader>
                        <IonToolbar>
                            <IonTitle>Admin Login</IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <IonGrid fixed={true}>
                        <IonRow class="ion-justify-content-center">
                            <IonCol
                                size="12"
                                size-md="6"
                                size-lg="6"
                                size-xl="6"
                            >
                                <IonList className="ion-margin-top">
                                    <IonItem className="ion-padding-start ion-padding-end">
                                        <IonLabel position="floating">
                                            Email
                                        </IonLabel>
                                        <IonInput
                                            name="email"
                                            value={text.email}
                                            onIonChange={(e) => handleChange(e)}
                                        />
                                    </IonItem>
                                    <IonItem className="ion-padding-start ion-padding-end">
                                        <IonLabel position="floating">
                                            Password
                                        </IonLabel>
                                        <IonInput
                                            type="password"
                                            name="password"
                                            value={text.password}
                                            onIonChange={(e) => handleChange(e)}
                                        />
                                    </IonItem>
                                    <section className="ion-no-border ion-padding ion-margin-bottom">
                                        <IonCheckbox
                                            slot="start"
                                            color="primary"
                                        />
                                        <IonLabel className="custom-label ion-padding">
                                            Show Password
                                        </IonLabel>
                                    </section>
                                    <IonButton
                                        color="primary"
                                        expand="block"
                                        className="ion-margin-bottom ion-padding-start ion-padding-end"
                                        size="large"
                                        onClick={Login}
                                    >
                                        <IonIcon
                                            slot="start"
                                            icon={keyOutline}
                                        />
                                        Login
                                    </IonButton>
                                    <IonButton
                                        onClick={GmailLogin}
                                        color="tertiary"
                                        expand="block"
                                        className="ion-margin-bottom ion-padding-start ion-padding-end"
                                        size="large"
                                    >
                                        <IonIcon
                                            slot="start"
                                            icon={mailOutline}
                                        />
                                        Gmail
                                    </IonButton>
                                </IonList>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonContent>
            </IonPage>
        </React.Fragment>
    )
}

export default Account
