import React, { useState, useEffect } from 'react'

import { IonPage, IonContent, IonGrid, IonRow, IonButton } from '@ionic/react'
import { Formik } from 'formik'
import { MDBDataTableV5 } from 'mdbreact'

import Header from '../admin/admin-header'
import api from '../Api'
const columns = [
    {
        field: 'leadSnp',
        label: 'Lead SNP',
        sort: true,
    },
    {
        field: 'effectAllele',
        label: 'Effect Allele',
        sort: true,
    },
    {
        field: 'gene',
        label: 'Gene',
        sort: true,
    },
    {
        field: 'effectSize',
        label: 'Effect Size',
        sort: true,
    },
    // {
    //     field: 'curatedGenotypes',
    //     label: 'Curated Genotypes',
    //     formatter: JSON.stringify,
    // },
]

const tertileColumns = [
    {
        field: 'classification',
        label: 'Classification',
    },
    {
        field: 'description',
        label: 'Description',
        editor: {
            //type: Type.TEXTAREA,
            rows: 10,
        },
    },
    {
        field: 'risk',
        label: 'Risk',
        editor: {
            //type: Type.TEXTAREA,
            rows: 10,
        },
    },
]

const proxyColumns = [
    {
        field: 'provider',
        label: 'Provider',
        editor: {
            //type: Type.SELECT,
            options: [
                {
                    value: 'Diagnomics',
                    label: 'Diagnomics',
                },
                {
                    value: 'Ancestry',
                    label: 'Ancestry',
                },
                {
                    value: '23andMe',
                    label: '23andMe',
                },
                {
                    value: 'My Heritage',
                    label: 'My Heritage',
                },
            ],
        },
    },
    {
        field: 'snp',
        label: 'SNP',
    },
    {
        field: 'effectAllele',
        label: 'Effect Allele',
    },
]

const Trait = (props) => {
    const [data, setData] = useState(
        //     {
        //     "name": null,
        //     "stars": 0,
        //     "caption": null,
        //     "about": null,
        //     "actionable": null,
        //     "description": null,
        //     "sexSpecific": null
        // }
        undefined
    )
    const [snps, setSnps] = useState([])
    const [tertiles, setTertiles] = useState([
        {
            classification: 'LOW',
            value: 1,
            description: null,
            risk: null,
        },
        {
            classification: 'INTERMEDIATE',
            value: 2,
            description: null,
            risk: null,
        },
        {
            classification: 'HIGH',
            value: 3,
            description: null,
            risk: null,
        },
    ])
    const [traitSpecific, setTraitSpecific] = useState({})
    console.log(props)

    useEffect(() => {
        api.get('/traits/' + props.match.params.traitId).then((res) => {
            setSnps(res.data['snps'])
            setTertiles(res.data['tertiles'])
            setTraitSpecific(res.data['traitSpecific'] || {})
            setData(res.data)
            console.log(res.data)
        })
    }, [])

    const addSnps = () => {
        const newSnp = {
            leadSnp: 'Click to Edit',
            effectAllele: 'Click to Edit',
            gene: 'Click to Edit',
            effectSize: 'Click to Edit',
            proxies: [],
        }
        setSnps([...snps, newSnp])
    }

    const expandRow = {
        className: 'bg-light',
        onlyOneExpanding: true,
        // onExpand: (row) => {
        //     if(row.proxies.length === 0){

        //     }
        // },
        renderer: (row) => (
            <div>
                <h3>Proxy SNPs</h3>
                {row.proxies.length > -1 ? (
                    <div>
                        {/* <BootstrapTable
                            bootstrap4
                            id={row.id}
                            keyField="id"
                            data={row.proxies}
                            columns={proxyColumns}
                            headerClasses="bg-white"
                            bodyClasses="bg-white"
                            cellEdit={cellEditFactory({ mode: 'dbclick' })}
                        /> */}
                    </div>
                ) : (
                    <div>No proxies</div>
                )}
            </div>
        ),
    }

    return (
        <React.Fragment>
            <IonPage>
                <IonContent>
                    <Header />
                    <IonGrid fixed={true}>
                        {data ? (
                            <Formik
                                initialValues={{ ...data }}
                                onSubmit={(
                                    values,
                                    { setSubmitting, restForm }
                                ) => {
                                    setSubmitting(true)
                                    const traitCreated = {
                                        id: null,
                                        ...values,
                                        snps: snps,
                                        tertiles: tertiles,
                                    }
                                    api.put('/traits', traitCreated).then(() =>
                                        setSubmitting(false)
                                    )
                                }}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <label>Name</label>
                                            <input
                                                id="name"
                                                name="name"
                                                className="form-control"
                                                onChange={handleChange}
                                                value={values.name}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Stars</label>
                                            <input
                                                id="stars"
                                                name="stars"
                                                className="form-control"
                                                onChange={handleChange}
                                                value={values.stars}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Caption</label>
                                            <input
                                                id="caption"
                                                name="caption"
                                                className="form-control"
                                                onChange={handleChange}
                                                value={values.caption}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>About</label>
                                            <textarea
                                                id="about"
                                                name="about"
                                                type="textarea"
                                                className="form-control"
                                                rows="8"
                                                onChange={handleChange}
                                                value={values.about}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Actionable</label>
                                            <select
                                                id="actionable"
                                                name="actionable"
                                                className="form-control"
                                                onChange={handleChange}
                                                value={values.actionable}
                                            >
                                                {[null, 'true', 'false'].map(
                                                    (item) => (
                                                        <option>{item}</option>
                                                    )
                                                )}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>Description</label>
                                            <textarea
                                                id="description"
                                                name="description"
                                                className="form-control"
                                                rows={5}
                                                onChange={handleChange}
                                                value={values.description}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Sex Specific</label>
                                            <select
                                                id="sexSpecific"
                                                name="sexSpecific"
                                                className="form-control"
                                                onChange={handleChange}
                                                value={values.sexSpecific}
                                            >
                                                {[null, 'male', 'female'].map(
                                                    (item) => (
                                                        <option>{item}</option>
                                                    )
                                                )}
                                            </select>
                                        </div>
                                        <div className="label-right">
                                            <IonButton
                                                type="submit"
                                                disable={isSubmitting}
                                            >
                                                Submit
                                            </IonButton>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        ) : null}
                        <IonRow>
                            <h2>SNPs</h2>
                            <br />
                            <MDBDataTableV5
                                hover
                                striped
                                bordered
                                data={{
                                    columns: columns,
                                    rows: snps,
                                }}
                            />
                            {/* <BootstrapTable
                                bootstrap4
                                id="snp-table"
                                keyField="id"
                                data={snps}
                                columns={columns}
                                headerClasses="bg-white"
                                bodyClasses="bg-white"
                                cellEdit={cellEditFactory({
                                    mode: 'dbclick',
                                    blurToSave: true,
                                    autoSelectText: true,
                                })}
                                expandRow={expandRow}
                            /> */}
                            {/* <IonButton className="ml-auto" onClick={addSnps}>
                                New SNP
                            </IonButton> */}
                        </IonRow>
                        <IonRow>
                            <h2>Tertiles</h2>
                            <MDBDataTableV5
                                hover
                                striped
                                bordered
                                data={{
                                    columns: tertileColumns,
                                    rows: tertiles,
                                }}
                            />
                            {/*                 <BootstrapTable
                                bootstrap4
                                keyField="id"
                                data={tertiles}
                                columns={tertileColumns}
                                headerClasses="bg-white"
                                bodyClasses="bg-white"
                                cellEdit={cellEditFactory({
                                    mode: 'dbclick',
                                    blurToSave: true,
                                })}
                            /> */}
                        </IonRow>
                        <IonRow>
                            {Object.keys(traitSpecific).map((key) => (
                                <p>
                                    {key} : {JSON.stringify(traitSpecific[key])}
                                </p>
                            ))}
                        </IonRow>
                    </IonGrid>
                </IonContent>
            </IonPage>
        </React.Fragment>
    )
}

export default Trait
