import React from 'react'

import {
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonList,
    IonMenu,
    IonPage,
    IonRouterOutlet,
    IonSplitPane,
    IonTabBar,
    IonTabs,
    IonTitle,
    IonToolbar,
    IonToggle,
} from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import {
    moonOutline,
    flaskOutline,
    person,
    peopleOutline,
    barChartOutline,
    removeOutline,
    linkOutline,
    headsetOutline,
} from 'ionicons/icons'
import { Redirect, Route } from 'react-router-dom'

import AdminAuth from '../admin/admin-auth'
import AdminClient from '../admin/admin-client'
import AdminCustomer from '../admin/admin-customer'
import AdminCustomerSupport from '../admin/admin-customer-support'
import AdminDashboard from '../admin/admin-dashboard'
import AdminLogin from '../admin/admin-login'
import AdminPack from '../admin/admin-pack'
import AdminPartner from '../admin/admin-partner'
import AdminReport from '../admin/admin-report'
import AdminTag from '../admin/admin-tags'
import AdminTrait from '../admin/admin-trait'
import AdminUser from '../admin/admin-user'
import PrivateRoute from '../components/PrivateRoute'
import Trait from '../pages/Trait'

const MainContent = () => {
    const url = window.location.pathname
    const lastSegment = url.includes('admin')

    const toggleDarkModeHandler = () => {
        document.body.classList.toggle('dark')
        console.log('trigger dark mode')
    }

    return (
        <React.Fragment>
            <IonContent>
                <IonSplitPane
                    contentId="main"
                    when="false"
                    className="bg-white"
                >
                    <IonMenu contentId="main" auto-hide>
                        <IonHeader>
                            <IonToolbar>
                                <IonTitle>Admin</IonTitle>
                            </IonToolbar>
                        </IonHeader>
                        <IonContent>
                            <IonList>
                                <IonItem href="/admin/analytics">
                                    <IonIcon
                                        icon={barChartOutline}
                                        className="ion-padding"
                                        color="primary"
                                    />
                                    Analytics
                                </IonItem>
                                <IonItem href="/admin/partners">
                                    <IonIcon
                                        icon={peopleOutline}
                                        className="ion-padding"
                                        color="primary"
                                    />
                                    Partners
                                </IonItem>
                                <IonItem>
                                    <IonIcon
                                        icon={flaskOutline}
                                        className="ion-padding"
                                        color="primary"
                                    />
                                    Lab
                                </IonItem>
                                <IonItem href="/admin/packs">
                                    <IonIcon
                                        icon={removeOutline}
                                        className="ion-padding ion-margin-start"
                                        color="primary"
                                    />
                                    Packs
                                </IonItem>
                                <IonItem href="/admin/reports">
                                    <IonIcon
                                        icon={removeOutline}
                                        className="ion-padding ion-margin-start"
                                        color="primary"
                                    />
                                    Reports
                                </IonItem>
                                <IonItem href="/admin/traits">
                                    <IonIcon
                                        icon={removeOutline}
                                        className="ion-padding ion-margin-start"
                                        color="primary"
                                    />
                                    Traits
                                </IonItem>
                                <IonItem href="/admin/tags">
                                    <IonIcon
                                        icon={linkOutline}
                                        className="ion-padding"
                                        color="primary"
                                    />
                                    Tags
                                </IonItem>
                                <IonItem href="/admin/adminUser" disabled>
                                    <IonIcon
                                        icon={person}
                                        className="ion-padding"
                                        color="primary"
                                    />
                                    Admin Users
                                </IonItem>
                                <IonItem href="/admin/customer" disabled>
                                    <IonIcon
                                        icon={peopleOutline}
                                        className="ion-padding"
                                        color="primary"
                                    />
                                    Customers/Members
                                </IonItem>
                                <IonItem href="/admin/customer/support">
                                    <IonIcon
                                        icon={headsetOutline}
                                        className="ion-padding"
                                        color="primary"
                                    />
                                    Customer Support
                                </IonItem>
                            </IonList>
                            <IonList>
                                <IonItem lines="none">
                                    <IonIcon
                                        className="ion-padding"
                                        color="primary"
                                        icon={moonOutline}
                                    />
                                    Enable Dark Mode
                                    <IonToggle
                                        slot="end"
                                        name="darkMode"
                                        onIonChange={toggleDarkModeHandler}
                                    />
                                </IonItem>
                            </IonList>
                        </IonContent>
                    </IonMenu>

                    <IonPage id="main">
                        <IonReactRouter>
                            <IonTabs>
                                <IonRouterOutlet>
                                    <Route
                                        path="/admin/auth"
                                        exact={true}
                                        component={AdminAuth}
                                    />
                                    <Route
                                        path="/admin/login"
                                        exact={true}
                                        component={AdminLogin}
                                    />
                                    <PrivateRoute
                                        path="/admin/analytics"
                                        exact={true}
                                        component={AdminDashboard}
                                    />
                                    <Route
                                        path="/admin/adminUser"
                                        exact={true}
                                        component={AdminUser}
                                    />
                                    <Route
                                        path="/admin/customer"
                                        exact={true}
                                        component={AdminCustomer}
                                    />
                                    <Route
                                        path="/admin/customer/support"
                                        exact={true}
                                        component={AdminCustomerSupport}
                                    />
                                    <PrivateRoute
                                        path="/admin/tags"
                                        exact={true}
                                        component={AdminTag}
                                    />
                                    <PrivateRoute
                                        path="/admin/traits"
                                        exact={true}
                                        component={AdminTrait}
                                    />
                                    <PrivateRoute
                                        path="/admin/traits/:traitId"
                                        exact={true}
                                        component={Trait}
                                    />
                                    <PrivateRoute
                                        path="/admin/reports"
                                        exact={true}
                                        component={AdminReport}
                                    />
                                    <PrivateRoute
                                        path="/admin/packs"
                                        exact={true}
                                        component={AdminPack}
                                    />
                                    <PrivateRoute
                                        path="/admin/partners"
                                        exact={true}
                                        component={AdminPartner}
                                    />
                                    <PrivateRoute
                                        path="/admin/clients/:partnerId"
                                        exact={true}
                                        component={AdminClient}
                                    />
                                    <Route exact path="/">
                                        <Redirect to="/admin/login" />
                                    </Route>
                                </IonRouterOutlet>

                                <IonTabBar
                                    slot="bottom"
                                    className={`ion-padding-top ion-padding-bottom ion-hide-md-up ${
                                        lastSegment == true ? 'ion-hide' : ''
                                    }`}
                                />
                            </IonTabs>
                        </IonReactRouter>
                    </IonPage>
                </IonSplitPane>
            </IonContent>
        </React.Fragment>
    )
}

export default MainContent
