import axios from 'axios'

const api = axios.create({
    baseURL: process.env.REACT_APP_ADMIN_API_URL,
    headers: {
        Authorization: 'bearer ' + localStorage.getItem('access_token'),
    },
})

api.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        if (error.response.status === 401) {
            const originalRequest = error.config

            //localStorage.setItem('access_token') =

            //return api(originalRequest)
        }
        return error
    }
)

export default api
