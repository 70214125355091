import React, { useEffect, useState } from 'react'

import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonCol,
    IonContent,
    IonGrid,
    IonRow,
    IonButton,
    IonIcon,
    IonChip,
    IonLabel,
} from '@ionic/react'
import {
    addOutline,
    createOutline,
    trashOutline,
    closeOutline,
} from 'ionicons/icons'
import {
    MDBDataTableV5,
    MDBModal,
    MDBModalHeader,
    MDBModalBody,
    MDBModalFooter,
} from 'mdbreact'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbreact/dist/css/mdb.css'
import makeAnimated from 'react-select/animated'
import CreatableSelect from 'react-select/creatable'

import api from '../Api'
import Header from './admin-header'
import { Helmet } from 'react-helmet'

const animatedComponents = makeAnimated()

const AdminPack = () => {
    const [showModal, setShowModal] = useState(false)
    const [update, setUpdate] = useState(false)
    const [selectedTags, setSelectedTags] = useState([])
    const [selectedReports, setSelectedReports] = useState([])
    const [selectedTraits, setSelectedTraits] = useState([])
    const [packs, setPacks] = useState([])
    const [options, setOptions] = useState([])
    const [optionReports, setOptionReports] = useState([])
    const [optionTraits, setOptionTraits] = useState([])
    const [state, setState] = useState({
        id: '',
        name: '',
        description: '',
        //tags: []
    })

    const getPacks = () => {
        api.get('/packs').then((response) => {
            setPacks({
                columns: [
                    {
                        label: '',
                        field: 'checkbox',
                        width: 30,
                        sort: 'disabled',
                    },
                    {
                        label: '#',
                        field: 'number',
                        width: 30,
                    },
                    {
                        label: 'Name',
                        field: 'name',
                        width: 200,
                        attributes: {
                            'aria-controls': 'DataTable',
                            'aria-label': 'Name',
                        },
                    },
                    {
                        label: 'Reports',
                        field: 'report',
                        width: 100,
                        sort: 'asc',
                    },
                    {
                        label: 'Traits',
                        field: 'trait',
                        width: 100,
                        sort: 'asc',
                    },
                    {
                        label: 'Tags',
                        field: 'tags',
                        width: 300,
                        sort: 'asc',
                    },
                    {
                        label: 'Details',
                        field: 'description',
                        width: 300,
                    },
                    {
                        label: '',
                        field: 'action',
                        width: 30,
                        sort: 'disabled',
                    },
                ],
                rows: response.data.map((item, index) => {
                    return (response.data[index] = {
                        ...response.data[index],
                        checkbox: (
                            <div
                                key={index}
                                className="custom-control custom-checkbox"
                            >
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id={`checked` + index}
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor={`checked` + index}
                                ></label>
                            </div>
                        ),
                        action: (
                            <React.Fragment key={index}>
                                <IonButton
                                    slot="icon-only"
                                    color="warning"
                                    onClick={() => updateAction(item)}
                                >
                                    <IonIcon icon={createOutline} />
                                </IonButton>
                                <IonButton
                                    slot="icon-only"
                                    color="danger"
                                    onClick={() => deleteAction(item)}
                                >
                                    <IonIcon icon={trashOutline} />
                                </IonButton>
                            </React.Fragment>
                        ),
                        tags: getAllPackTags(item.tags),
                        report: getAllPackReports(item.reports),
                        trait: getAllPackTraits(item.traits),
                        number: index + 1,
                    })
                }),
            })
        })
    }

    //console.log('packs ', packs.rows)

    const getTags = () => {
        api.get('/tags').then((res) => {
            setOptions(res.data)
        })
    }

    const getReports = () => {
        api.get('/reports').then((res) => {
            setOptionReports(res.data)
        })
    }

    const getTraits = () => {
        api.get('/traits').then((res) => {
            setOptionTraits(res.data)
        })
    }

    useEffect(() => {
        getPacks()
        getReports()
        getTraits()
        getTags()
    }, [])

    const getAllPackTags = (tags) => {
        return tags.map((itms, i) => (
            <div key={i}>
                <IonChip color="success">
                    <IonLabel>{itms}</IonLabel>
                </IonChip>
            </div>
        ))
    }

    const getAllPackReports = (reports) => {
        return reports.map((itms, i) => (
            <div key={i}>
                <IonChip color="success">
                    <IonLabel>{itms}</IonLabel>
                </IonChip>
            </div>
        ))
    }

    const getAllPackTraits = (traits) => {
        return traits.map((itms, i) => (
            <div key={i}>
                <IonChip color="success">
                    <IonLabel>{itms}</IonLabel>
                </IonChip>
            </div>
        ))
    }

    const updateAction = (pack) => {
        getPackTags(pack)
        getPackReports(pack)
        getPackTraits(pack)
        setState(pack)
        setUpdate(true)
        setShowModal(true)
    }

    const deleteAction = () => {
        console.log('deleteaction')
    }

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        })
    }

    const handleChangeSelectTags = (e) => {
        setSelectedTags(e)
    }

    const handleChangeSelectReports = (e) => {
        setSelectedReports(e)
    }

    const handleChangeSelectTraits = (e) => {
        setSelectedTraits(e)
    }

    const addPack = () => {
        setShowModal(true)
        setUpdate(false)
        setState({
            name: '',
            type: '',
            description: '',
        })
        setSelectedTags([])
        setSelectedReports([])
        setSelectedTraits([])
    }

    const createPack = () => {
        const data = {
            name: state.name,
            //type: state.type,
            description: state.description,
        }

        api.post('/packs', data).then((res) => {
            updatePackTags(res.data)
            updatePackReports(res.data)
            updatePackTraits(res.data)
            setShowModal(false)
            getPacks()
        })
    }

    const getPackTags = (pack) => {
        api.get('/packs/' + pack.id + '/tags').then((response) => {
            setSelectedTags(response.data)
        })
    }

    const updatePackTags = (pack) => {
        api.put('/packs/' + pack.id + '/tags', selectedTags)
    }

    const getPackReports = (pack) => {
        api.get('/packs/' + pack.id + '/reports').then((response) => {
            setSelectedReports(response.data)
        })
    }

    const updatePackReports = (pack) => {
        const selectedReportId = []
        selectedReports.map((item) => {
            selectedReportId.push({
                reportId: item.id,
            })
        })

        const data = {
            reports: selectedReportId,
        }

        api.put('/packs/' + pack.id + '/reports', data)
    }

    const getPackTraits = (pack) => {
        api.get('/packs/' + pack.id + '/traits').then((response) => {
            setSelectedTraits(response.data)
        })
    }

    const updatePackTraits = (pack) => {
        const selectedTraitId = []
        selectedTraits.map((item) => {
            selectedTraitId.push({
                traitId: item.id,
            })
        })

        const data = {
            traits: selectedTraitId,
        }

        api.put('/packs/' + pack.id + '/traits', data)
    }

    const updatePack = () => {
        const data = {
            id: state.id,
            name: state.name,
            //type: state.type,
            description: state.description,
        }

        api.put('/packs/' + state.id, data).then((res) => {
            updatePackReports(res.data)
            updatePackTraits(res.data)
            updatePackTags(res.data)
            setShowModal(false)
            getPacks()
        })
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>LifeDNA | ADMIN | Pack Management</title>
            </Helmet>
            <IonPage>
                <Header />
                <IonContent>
                    <IonHeader>
                        <IonToolbar>
                            <IonTitle>Pack Management</IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <IonGrid>
                        <IonRow>
                            <IonCol size="12">
                                <IonButton
                                    color="tertiary"
                                    onClick={() => addPack()}
                                >
                                    <IonIcon slot="end" icon={addOutline} />
                                    {'Add New Pack'}
                                </IonButton>
                                <MDBDataTableV5
                                    hover
                                    striped
                                    bordered
                                    entriesOptions={[10, 20, 30]}
                                    entries={10}
                                    pagesAmount={10}
                                    data={packs}
                                    pagingTop
                                    searchTop
                                    searchBottom={false}
                                />
                            </IonCol>
                        </IonRow>
                    </IonGrid>

                    <MDBModal isOpen={showModal} fullHeight position="right">
                        <MDBModalHeader>
                            {update === true ? 'Update Pack' : 'Add New Pack'}
                        </MDBModalHeader>
                        <MDBModalBody>
                            <div className="form-group">
                                <label htmlFor="formGroupExampleInput">
                                    Pack Name
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="name"
                                    value={state.name}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">
                                    Description
                                </label>
                                <textarea
                                    className="form-control"
                                    name="description"
                                    type="textarea"
                                    rows="8"
                                    value={state.description}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="formGroupExampleInput">
                                    Reports
                                </label>
                                <CreatableSelect
                                    closeMenuOnSelect={false}
                                    options={optionReports}
                                    components={animatedComponents}
                                    isMulti
                                    name="reports"
                                    getOptionValue={(option) => option['id']}
                                    getOptionLabel={(option) => option['name']}
                                    isClearable
                                    onChange={handleChangeSelectReports}
                                    value={selectedReports}
                                    getNewOptionData={(
                                        inputValue,
                                        optionLabel
                                    ) => ({
                                        id: inputValue,
                                        name: optionLabel,
                                    })}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="formGroupExampleInput">
                                    Traits
                                </label>
                                <CreatableSelect
                                    closeMenuOnSelect={false}
                                    options={optionTraits}
                                    components={animatedComponents}
                                    isMulti
                                    name="traits"
                                    getOptionValue={(option) => option['id']}
                                    getOptionLabel={(option) => option['name']}
                                    isClearable
                                    onChange={handleChangeSelectTraits}
                                    value={selectedTraits}
                                    getNewOptionData={(
                                        inputValue,
                                        optionLabel
                                    ) => ({
                                        id: inputValue,
                                        name: optionLabel,
                                    })}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="formGroupExampleInput">
                                    Tags
                                </label>
                                <CreatableSelect
                                    closeMenuOnSelect={false}
                                    options={options}
                                    components={animatedComponents}
                                    isMulti
                                    name="tags"
                                    getOptionValue={(option) => option['id']}
                                    getOptionLabel={(option) => option['name']}
                                    isClearable
                                    onChange={handleChangeSelectTags}
                                    value={selectedTags}
                                    getNewOptionData={(
                                        inputValue,
                                        optionLabel
                                    ) => ({
                                        id: inputValue,
                                        name: optionLabel,
                                    })}
                                    //defaultValue={selectedValue}
                                />
                            </div>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <IonButton
                                color="danger"
                                onClick={() => setShowModal(false)}
                            >
                                Cancel
                            </IonButton>
                            {update === true ? (
                                <IonButton
                                    color="tertiary"
                                    onClick={updatePack}
                                >
                                    Update Changes
                                </IonButton>
                            ) : (
                                <IonButton color="success" onClick={createPack}>
                                    Save Changes
                                </IonButton>
                            )}
                        </MDBModalFooter>
                    </MDBModal>
                </IonContent>
            </IonPage>
        </React.Fragment>
    )
}

export default AdminPack
