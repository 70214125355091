import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbreact/dist/css/mdb.css'

import React, { useEffect, useState } from 'react'

import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
} from '@ionic/react'
import { addOutline, createOutline, trashOutline } from 'ionicons/icons'
import {
    MDBDataTableV5,
    MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBModalHeader,
} from 'mdbreact'
import Select from 'react-select'

import api from '../Api'
import Header from './admin-header'
import { Helmet } from 'react-helmet'

const AdminReport = () => {
    const [showModal, setShowModal] = useState(false)
    const [update, setUpdate] = useState(false)
    const [selectedValue, setSelectedValue] = useState([])
    const [tags, setTags] = useState([])
    const [options, setOptions] = useState([
        {
            value: 'Brand',
            label: 'Brand',
        },
        {
            value: 'Ingredient',
            label: 'Ingredient',
        },
        {
            value: 'Trait',
            label: 'Trait',
        },
        {
            value: 'Report',
            label: 'Report',
        },
        {
            value: 'Tenant',
            label: 'Tenant',
        },
    ])
    const [state, setState] = useState({
        id: '',
        name: '',
        type: '',
        description: '',
    })

    const getTags = () => {
        api.get('/tags').then((response) =>
            setTags({
                columns: [
                    {
                        label: '',
                        field: 'checkbox',
                        width: 30,
                        sort: 'disabled',
                    },
                    {
                        label: '#',
                        field: 'number',
                        width: 30,
                    },
                    {
                        label: 'Name',
                        field: 'name',
                        width: 200,
                        attributes: {
                            'aria-controls': 'DataTable',
                            'aria-label': 'Name',
                        },
                    },
                    {
                        label: 'Category',
                        field: 'type',
                        width: 100,
                        sort: 'disabled',
                    },
                    {
                        label: 'Details',
                        field: 'description',
                        width: 300,
                    },
                    {
                        label: '',
                        field: 'action',
                        width: 30,
                        sort: 'disabled',
                    },
                ],
                rows: response.data.map((item, index) => {
                    return (response.data[index] = {
                        ...response.data[index],
                        checkbox: (
                            <p>
                                <div className="custom-control custom-checkbox">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id={`checked` + index}
                                    />
                                    <label
                                        className="custom-control-label"
                                        htmlFor={`checked` + index}
                                    ></label>
                                </div>
                            </p>
                        ),
                        action: (
                            <>
                                <IonButton
                                    slot="icon-only"
                                    color="warning"
                                    onClick={() => updateAction(item)}
                                >
                                    <IonIcon icon={createOutline} />
                                </IonButton>
                                <IonButton
                                    slot="icon-only"
                                    color="danger"
                                    onClick={() => deleteAction(item)}
                                >
                                    <IonIcon icon={trashOutline} />
                                </IonButton>
                            </>
                        ),
                        number: index + 1,
                    })
                }),
            })
        )
    }

    useEffect(() => {
        getTags()
    }, [])

    console.log(tags)

    const deleteAction = (tag) => {
        deleteTag(tag)
    }

    const deleteTag = (tag) => {
        api.delete('/tags/' + tag.id).then(() => {
            setTags([])
            getTags()
        })
    }

    const handleChange = (e) => {
        const newState = {
            ...state,
            [e.target.name]: e.target.value,
        }

        setState(newState)
    }

    const handleChangeSelect = (e) => {
        const newState = {
            ...state,
            type: e.value,
        }

        setState(newState)
        setSelectedValue(e.value)
    }

    const updateAction = (tag) => {
        setState(tag)
        setSelectedValue(tag.type)
        setUpdate(true)
        setShowModal(true)
    }

    const addTag = () => {
        setShowModal(true)
        setUpdate(false)
        setState({
            name: '',
            description: '',
        })
        setSelectedValue([])
    }

    const createTag = () => {
        const data = {
            name: state.name,
            type: selectedValue,
            description: state.description,
        }

        api.post('/tags', data).then(() => {
            setShowModal(false)
            getTags()
        })
    }

    const updateTag = () => {
        const data = {
            id: state.id,
            name: state.name,
            type: state.type,
            description: state.description,
        }

        setSelectedValue(state.type)

        api.put('/tags/' + state.id, data).then(() => {
            setShowModal(false)
            getTags()
        })
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>LifeDNA | ADMIN | Tag Management</title>
            </Helmet>
            <IonPage>
                <Header />
                <IonContent>
                    <IonHeader>
                        <IonToolbar>
                            <IonTitle>Tag Management</IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <IonGrid>
                        <IonRow>
                            <IonCol size="12">
                                <IonButton
                                    color="tertiary"
                                    onClick={() => addTag()}
                                >
                                    <IonIcon slot="end" icon={addOutline} />
                                    {'Add New Tag'}
                                </IonButton>
                                <MDBDataTableV5
                                    hover
                                    striped
                                    bordered
                                    entriesOptions={[10, 20, 30]}
                                    entries={10}
                                    pagesAmount={10}
                                    data={tags}
                                    pagingTop
                                    searchTop
                                    searchBottom={false}
                                />
                            </IonCol>
                        </IonRow>
                    </IonGrid>

                    <MDBModal isOpen={showModal} fullHeight position="right">
                        <MDBModalHeader>
                            {update === true ? 'Update Tag' : 'Add New Tag'}
                        </MDBModalHeader>
                        <MDBModalBody>
                            <div className="form-group">
                                <label htmlFor="formGroupExampleInput">
                                    Tag Name
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="name"
                                    value={state.name}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="formGroupExampleInput">
                                    Category
                                </label>
                                <Select
                                    closeMenuOnSelect={true}
                                    options={options}
                                    name="tags"
                                    value={{
                                        label: selectedValue,
                                        value: selectedValue,
                                    }}
                                    isClearable
                                    onChange={handleChangeSelect}
                                    //defaultValue={selectedValue}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">
                                    Description
                                </label>
                                <textarea
                                    className="form-control"
                                    name="description"
                                    type="textarea"
                                    rows="8"
                                    value={state.description}
                                    onChange={handleChange}
                                />
                            </div>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <IonButton
                                color="danger"
                                onClick={() => setShowModal(false)}
                            >
                                Cancel
                            </IonButton>
                            {update === true ? (
                                <IonButton color="tertiary" onClick={updateTag}>
                                    Update Changes
                                </IonButton>
                            ) : (
                                <IonButton color="success" onClick={createTag}>
                                    Save Changes
                                </IonButton>
                            )}
                        </MDBModalFooter>
                    </MDBModal>
                </IonContent>
            </IonPage>
        </React.Fragment>
    )
}

export default AdminReport
