import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbreact/dist/css/mdb.css'

import React, { useEffect, useState } from 'react'

import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
} from '@ionic/react'
import { eyeOutline } from 'ionicons/icons'
import { addOutline, trashOutline } from 'ionicons/icons'
import { MDBDataTableV5, MDBRow, MDBCol } from 'mdbreact'
import { useParams } from 'react-router-dom'

import api from '../Api'
import Header from './admin-header'
import { Helmet } from 'react-helmet'

const AdminReport = () => {
    const [clients, setClients] = useState([])
    const [toggle, setToggle] = useState({
        id: '',
        toggle: false,
    })

    let { partnerId } = useParams()

    const getClients = () => {
        api.get('/partners/' + partnerId + '/clients').then((response) =>
            setClients({
                columns: [
                    {
                        label: '',
                        field: 'checkbox',
                        width: 30,
                        sort: 'disabled',
                    },
                    {
                        label: '#',
                        field: 'number',
                        width: 30,
                    },
                    {
                        label: 'Id',
                        field: 'clientId',
                        width: 30,
                    },
                    {
                        label: 'Secret',
                        field: 'secret',
                        width: 30,
                    },
                    {
                        label: 'Description',
                        field: 'description',
                        width: 30,
                        sort: 'asc',
                    },
                    {
                        label: '',
                        field: 'action',
                        width: 30,
                        sort: 'disabled',
                    },
                ],
                rows: response.data.map((item, index) => {
                    return (response.data[index] = {
                        ...response.data[index],
                        checkbox: (
                            <p>
                                <div className="custom-control custom-checkbox">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id={`checked` + index}
                                    />
                                    <label
                                        className="custom-control-label"
                                        htmlFor={`checked` + index}
                                    ></label>
                                </div>
                            </p>
                        ),
                        action: (
                            <>
                                <IonButton
                                    slot="icon-only"
                                    color="danger"
                                    onClick={() => deleteAction(item)}
                                >
                                    <IonIcon icon={trashOutline} />
                                </IonButton>
                            </>
                        ),
                        number: index + 1,
                        secret: (
                            <>
                                <MDBRow>
                                    {toggle.id === index && toggle.toggle ? (
                                        <MDBCol size="7">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={item.clientSecret}
                                                disabled
                                            />
                                        </MDBCol>
                                    ) : null}

                                    <MDBCol
                                        size="2"
                                        className="ml-0 mr-0 pl-0 pr-0"
                                    >
                                        <IonButton
                                            title={'View Client Secret'}
                                            slot="icon-only"
                                            color="tertiary"
                                            onClick={() =>
                                                toggleDisplay(event, index)
                                            }
                                        >
                                            <IonIcon icon={eyeOutline} />
                                        </IonButton>
                                    </MDBCol>
                                </MDBRow>
                            </>
                        ),
                    })
                }),
            })
        )
    }

    console.log(clients)

    useEffect(() => {
        getClients()
    }, [toggle])

    const toggleDisplay = (event, index) => {
        setToggle({
            ...toggle[index],
            id: index,
            toggle: !toggle.toggle,
        })
        event.preventDefault()
    }

    const deleteAction = (client) => {
        console.log(client)
        api.delete('/clients/' + client.clientId).then(() => {
            getClients()
        })
    }

    const createClient = () => {
        api.post('/partners/' + partnerId + '/clients').then(() => {
            getClients()
        })
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>LifeDNA | ADMIN | Client Management</title>
            </Helmet>
            <IonPage>
                <Header />
                <IonContent>
                    <IonHeader>
                        <IonToolbar>
                            <IonTitle>Client Management</IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <IonGrid>
                        <IonRow>
                            <IonCol size="12">
                                <IonButton
                                    color="tertiary"
                                    onClick={() => createClient()}
                                >
                                    <IonIcon slot="end" icon={addOutline} />
                                    {'Add New Client'}
                                </IonButton>
                                <MDBDataTableV5
                                    hover
                                    striped
                                    bordered
                                    entriesOptions={[10, 20, 30]}
                                    entries={10}
                                    pagesAmount={10}
                                    data={clients}
                                    pagingTop
                                    searchTop
                                    searchBottom={false}
                                />
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonContent>
            </IonPage>
        </React.Fragment>
    )
}

export default AdminReport
