import React, { useState, useEffect } from 'react';
import { IonButton, IonButtons, IonIcon, IonMenuButton, IonTitle, IonToolbar, IonPopover, IonList, IonItem, IonImg, IonAvatar, IonLabel, IonMenuToggle } from '@ionic/react';
import { ellipsisVerticalOutline, settingsOutline, logOutOutline, peopleOutline } from 'ionicons/icons';
import { SIZE_TO_MEDIA } from '@ionic/core/dist/collection/utils/media'
import { GoogleLogout } from 'react-google-login';
import { useHistory } from "react-router-dom";
import Logo from '../theme/img/logo-white.svg';
import { gapi } from 'gapi-script';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';


const AdminHeader = () => {
  const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });
  const history = useHistory();
  let accessToken = localStorage.getItem('access_token');
  let userName = localStorage.getItem('user_name');
  let userEmail = localStorage.getItem('user_email');
  let userImg = localStorage.getItem('user_img');


  const toggleMenu = () => {
    const splitPane = document.querySelector('ion-split-pane')
    if (window.matchMedia(SIZE_TO_MEDIA[splitPane.when] || splitPane.when).matches)
        splitPane.classList.toggle('split-pane-visible')
    }


    const executer = () => {
      let path = "/admin/login"
      history.push(path);
      setShowPopover({ showPopover: false, event: undefined })
      localStorage.clear();
      
    }
  
  const logoutSuccess = (response) => {
   
    const auth2 = gapi.auth2.getAuthInstance();
    console.log("auth2 ",auth2)
    if (auth2 != null) {
        auth2.signOut().then(
            auth2.disconnect().then(
              executer()
              
             )
            
        )
    }
    
  }

  const logoutGoogle = async () => {
     let path = "/admin/login"
     history.push(path);
    setShowPopover({ showPopover: false, event: undefined })
    localStorage.clear();
    await GoogleAuth.signOut();
    console.log("fire")
  }


  return (
    <React.Fragment>
     <IonToolbar color="dark" style={{zIndex:"0"}}>

        <IonButtons slot="start">
          <IonMenuToggle autoHide="false">
          <IonButton onClick={toggleMenu}>
            <IonMenuButton auto-hide="false"></IonMenuButton>
          </IonButton>
          </IonMenuToggle>
        </IonButtons>
        { accessToken ?
        <IonButtons slot="end" 
        onClick={
        (e) => {
          e.persist();
          setShowPopover({ showPopover: true, event: e })
        }}>
          <IonIcon slot="icon-only" icon={ellipsisVerticalOutline}></IonIcon>
        </IonButtons>
        : null
        }
        <IonTitle text-center><img className="img-responsive" src={Logo} alt=""/></IonTitle>
      </IonToolbar>

      <IonPopover
        cssClass='my-custom-class'
        event={popoverState.event}
        isOpen={popoverState.showPopover}
        onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
      >
        <IonList>
            <IonItem button>
              <IonAvatar slot="start">
                <IonImg src={userImg} alt={userName}/>
              </IonAvatar>
              <IonLabel>
                <h3>{userName}</h3>
                <p>{userEmail}</p>
              </IonLabel>
            </IonItem>
            <IonItem button><IonIcon slot="start" icon={peopleOutline} size="small"></IonIcon> Accounts</IonItem>
            <IonItem button><IonIcon slot="start" icon={settingsOutline} size="small"></IonIcon> Settings</IonItem>
            
            <IonItem button lines="none" onClick={logoutGoogle} >
                  <IonIcon slot="start" icon={logOutOutline} size="small"></IonIcon> Logout Gmail
             </IonItem>
            <GoogleLogout
              clientId="223654416942-65ffsvhll9hmugkc546c115e7smali33.apps.googleusercontent.com"
              onLogoutSuccess={logoutSuccess}
              onLogoutFailure={err => console.log(err)}
                className="ion-hide"
              />
     
          </IonList>
      </IonPopover>

    </React.Fragment>
  );
};

export default AdminHeader;
