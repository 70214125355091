import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbreact/dist/css/mdb.css'

import React, { useEffect, useState } from 'react'

import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
} from '@ionic/react'
import {
    addOutline,
    createOutline,
    trashOutline,
    eyeOutline,
} from 'ionicons/icons'
import {
    MDBDataTableV5,
    MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBModalHeader,
} from 'mdbreact'
import Select from 'react-select'

import api from '../Api'
import Header from './admin-header'
import { Helmet } from 'react-helmet'

const AdminPartner = () => {
    const [showModal, setShowModal] = useState(false)
    const [update, setUpdate] = useState(false)
    const [partners, setPartners] = useState([])
    const [state, setState] = useState({
        id: '',
        name: '',
    })

    const getPartners = () => {
        api.get('/partners').then((response) =>
            setPartners({
                columns: [
                    {
                        label: '',
                        field: 'checkbox',
                        width: 30,
                        sort: 'disabled',
                    },
                    {
                        label: '#',
                        field: 'number',
                        width: 30,
                    },
                    {
                        label: 'Name',
                        field: 'name',
                        width: 200,
                        attributes: {
                            'aria-controls': 'DataTable',
                            'aria-label': 'Name',
                        },
                    },
                    {
                        label: '',
                        field: 'action',
                        width: 30,
                        sort: 'disabled',
                    },
                ],
                rows: response.data.map((item, index) => {
                    return (response.data[index] = {
                        ...response.data[index],
                        checkbox: (
                            <p>
                                <div className="custom-control custom-checkbox">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id={`checked` + index}
                                    />
                                    <label
                                        className="custom-control-label"
                                        htmlFor={`checked` + index}
                                    ></label>
                                </div>
                            </p>
                        ),
                        action: (
                            <>
                                <IonButton
                                    title={
                                        'View Clients for Partner' + item.name
                                    }
                                    slot="icon-only"
                                    color="tertiary"
                                    href={'/admin/clients/' + item.id}
                                >
                                    <IonIcon icon={eyeOutline} />
                                </IonButton>
                            </>
                        ),
                        number: index + 1,
                    })
                }),
            })
        )
    }

    console.log(partners)

    useEffect(() => {
        getPartners()
    }, [])

    const handleChange = (e) => {
        const newState = {
            ...state,
            [e.target.name]: e.target.value,
        }

        setState(newState)
    }

    const addPartner = () => {
        setShowModal(true)
        setUpdate(false)
        setState({
            id: '',
            name: '',
        })
    }

    const createPartner = () => {
        const data = {
            name: state.name,
            active: true,
        }

        api.post('/partners', data).then(() => {
            setShowModal(false)
            getPartners()
        })
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>LifeDNA | ADMIN | Partner Management</title>
            </Helmet>
            <IonPage>
                <Header />
                <IonContent>
                    <IonHeader>
                        <IonToolbar>
                            <IonTitle>Partner Management</IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <IonGrid>
                        <IonRow>
                            <IonCol size="12">
                                <IonButton
                                    color="tertiary"
                                    onClick={() => addPartner()}
                                >
                                    <IonIcon slot="end" icon={addOutline} />
                                    {'Add New Partner'}
                                </IonButton>
                                <MDBDataTableV5
                                    hover
                                    striped
                                    bordered
                                    entriesOptions={[10, 20, 30]}
                                    entries={10}
                                    pagesAmount={10}
                                    data={partners}
                                    pagingTop
                                    searchTop
                                    searchBottom={false}
                                />
                            </IonCol>
                        </IonRow>
                    </IonGrid>

                    <MDBModal isOpen={showModal} fullHeight position="right">
                        <MDBModalHeader>
                            {update === true
                                ? 'Update Partner'
                                : 'Add New Partner'}
                        </MDBModalHeader>
                        <MDBModalBody>
                            <div className="form-group">
                                <label htmlFor="formGroupExampleInput">
                                    Name
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="name"
                                    value={state.name}
                                    onChange={handleChange}
                                />
                            </div>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <IonButton
                                color="danger"
                                onClick={() => setShowModal(false)}
                            >
                                Cancel
                            </IonButton>
                            {update === true ? (
                                <IonButton
                                    color="tertiary"
                                    onClick={createPartner}
                                >
                                    Update Changes
                                </IonButton>
                            ) : (
                                <IonButton
                                    color="success"
                                    onClick={createPartner}
                                >
                                    Save Changes
                                </IonButton>
                            )}
                        </MDBModalFooter>
                    </MDBModal>
                </IonContent>
            </IonPage>
        </React.Fragment>
    )
}

export default AdminPartner
