import React from 'react';
import { IonPage, IonHeader, IonToolbar, IonTitle, IonCol, IonContent, IonGrid, IonRow, IonText, IonButton, IonCard } from '@ionic/react';
import Header from './admin-header';


const AdminUser = () => {


  return (
    <React.Fragment>
      <IonPage>
      <Header/>
      <IonContent>
      <IonHeader >
        <IonToolbar>
          <IonTitle>User Management</IonTitle>
        </IonToolbar>
      </IonHeader>
      
      </IonContent>
      </IonPage> 
    
    </React.Fragment>
  );
};

export default AdminUser;
