import React, { useEffect } from 'react'

import axios from 'axios'
import qs from 'qs'
import { useHistory } from 'react-router'

const AdminAuth = () => {
    const params = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
    })
    const history = useHistory()

    useEffect(() => {
        axios
            .get(process.env.REACT_APP_GOOGLE_CALLBACK, { params: params })
            .then((response) => {
                localStorage.setItem('access_token', response.data.access_token)
                history.push('/admin/analytics')
            })
    })

    return <React.Fragment />
}

export default AdminAuth
